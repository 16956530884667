import React, { Component } from "react";

import AuthManager from "../../utils/AuthManager";
import Event from "../../utils/Event";
import General from "../../utils/General";

export default class UserPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: props.currentUser,
    };
  }

  componentDidMount() {
    Event.on("USER_UPDATED", () =>
      this.setState({
        currentUser: AuthManager.currentUser,
      })
    );
  }

  componentWillUnmount() {
    Event.off("USER_UPDATED");
  }

  _onLogoutPressed() {
    AuthManager.logout().then(() => {
      window.location = "/account";
    });
  }

  render() {
    const { currentUser } = this.state;

    const userAvatar = currentUser.user?.avatar?.original;
    const userFirstName = currentUser.user?.first_name;
    const userLastName = currentUser.user?.last_name;

    return (
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
        data-kt-menu="true"
        data-popper-placement="bottom-end"
        style={{
          zIndex: 105,
          position: "fixed",
          inset: "0px 0px auto auto",
          margin: 0,
          transform: "translate3d(-30px, 65px, 0px)",
        }}
      >
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center default-cursor px-3">
            <div
              className="symbol symbol-50px me-5"
              style={{
                backgroundColor: !userAvatar && "whitesmoke",
                width: !userAvatar && "50px",
                height: !userAvatar && "50px",
              }}
            >
              {userAvatar ? (
                <img src={userAvatar} alt="User" />
              ) : (
                <h1 className="m-0 text-center my-4">
                  {General.getInitials(`${userFirstName} ${userLastName}`)}
                </h1>
              )}
            </div>
            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {userFirstName} {userLastName}
              </div>
              <a
                href="javascript:;"
                className="fw-bold text-muted text-hover-primary fs-7"
              >
                {currentUser.user?.email}
              </a>
            </div>
          </div>
        </div>

        <div className="separator my-2" />
        <div className="separator my-2" />

        <div className="menu-item px-5 my-1">
          <a href="/account-settings" className="menu-link px-5">
            Account Settings
          </a>
        </div>

        <div className="menu-item px-5">
          <a href="/account" className="menu-link px-5">
            Sign Out
          </a>
        </div>
      </div>
    );
  }
}
