import { fabric } from 'fabric';

import { HSL } from "./HSL"
import { Lisbon } from "./Lisbon"
import { Cabin } from "./Cabin"
import { Cayman } from "./Cayman"
import { Creamy } from "./Creamy"
import { Cocktail } from "./Cocktail"
import { Exposure } from "./Exposure"
import { Highlights } from "./Highlights"
import { Mocha } from "./Mocha"
import { MochaV6 } from "./MochaV6"
import { Memory } from "./Memory"
import { MemoryGrain } from "./MemoryGrain"
import { BlackAndWhite } from "./BlackAndWhite"
import { BlackAndWhiteV2 } from "./BlackAndWhiteV2"
import { BlackAndWhiteGrain } from "./BlackAndWhiteGrain"
import { BlackAndWhiteGrainV2 } from "./BlackAndWhiteGrainV2"
import { Waves } from './Waves';

const Filters = {
  brightness: new fabric.Image.filters.Brightness(),
  saturation: new fabric.Image.filters.Saturation(),
  contrast: new fabric.Image.filters.Contrast(),
  exposure: new fabric.Image.filters.Exposure(),
  highlights: new fabric.Image.filters.Highlights(),
  hsl: new fabric.Image.filters.HSL(),

  // CustomFilters
  Lightroom: new fabric.Image.filters.Lightroom(),
  Lisbon: new fabric.Image.filters.Lisbon(),
  Cabin: new fabric.Image.filters.Cabin(),
  Cayman: new fabric.Image.filters.Cayman(),
  Creamy: new fabric.Image.filters.Creamy(),
  Cocktail: new fabric.Image.filters.Cocktail(),
  Mocha: new fabric.Image.filters.Mocha(),
  MochaV6: new fabric.Image.filters.MochaV6(),
  Memory: new fabric.Image.filters.Memory(),
  MemoryGrain: new fabric.Image.filters.MemoryGrain(),
  BlackAndWhite: new fabric.Image.filters.BlackAndWhite(),
  BlackAndWhiteV2: new fabric.Image.filters.BlackAndWhiteV2(),
  BlackAndWhiteGrain: new fabric.Image.filters.BlackAndWhiteGrain(),
  BlackAndWhiteGrainV2: new fabric.Image.filters.BlackAndWhiteGrainV2(),
  Waves: new fabric.Image.filters.Waves(),
}

const DisplayFilters = [
  Filters.Lightroom,
  Filters.BlackAndWhite,
  Filters.BlackAndWhiteV2,
  Filters.BlackAndWhiteGrain,
  Filters.BlackAndWhiteGrainV2,
  Filters.Cabin,
  Filters.Cocktail,
  Filters.Cayman,
  Filters.Creamy,
  Filters.Lisbon,
  Filters.Memory,
  Filters.MemoryGrain,
  Filters.Mocha,
  Filters.MochaV6,
  // temporarily hide
  // Filters.Waves
]

export { Filters, DisplayFilters }
