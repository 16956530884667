
import { fabric } from 'fabric';

const COLORS = ["Aqua", "Blue", "Purple", "Magenta", "Red", "Orange", "Yellow", "Green"]

const HELPER_FUNCTIONS = `
  const int BIT_COUNT = 32;

  float random(float x, float seed) {
    return fract(sin(x) * 43758.5453);
  }

  float _min(float x, float y, float z){
    return min(min(x, y), z);
  }

  float _max(float x, float y, float z){
    return max(max(x, y), z);
  }

  int modi(int x, int y) {
      return x - y * (x / y);
  }

  int and(int a, int b) {
      int result = 0;
      int n = 1;

      for(int i = 0; i < BIT_COUNT; i++) {
          if ((modi(a, 2) == 1) && (modi(b, 2) == 1)) {
              result += n;
          }

          a = a / 2;
          b = b / 2;
          n = n * 2;

          if(!(a > 0 && b > 0)) {
              break;
          }
      }
      return result;
  }

  // https://stackoverflow.com/a/17309861
  vec3 rgb2hsl(vec3 col){
    float red   = col.r;
    float green = col.g;
    float blue  = col.b;

    float minc  = _min( col.r, col.g, col.b );
    float maxc  = _max( col.r, col.g, col.b );
    float delta = maxc - minc;

    float lum = (minc + maxc) * 0.5;
    float sat = 0.0;
    float hue = 0.0;

    if (lum > 0.0 && lum < 1.0) {
        float mul = (lum < 0.5)  ?  (lum)  :  (1.0-lum);
        sat = delta / (mul * 2.0);
    }

    vec3 masks = vec3(
        (maxc == red   && maxc != green) ? 1.0 : 0.0,
        (maxc == green && maxc != blue)  ? 1.0 : 0.0,
        (maxc == blue  && maxc != red)   ? 1.0 : 0.0
    );

    vec3 adds = vec3(
              ((green - blue ) / delta),
        2.0 + ((blue  - red  ) / delta),
        4.0 + ((red   - green) / delta)
    );

    float deltaGtz = (delta > 0.0) ? 1.0 : 0.0;

    hue += dot( adds, masks );
    hue *= deltaGtz;
    hue /= 6.0;

    if (hue < 0.0)
        hue += 1.0;

    return vec3( hue, sat, lum);
  }

  // https://stackoverflow.com/a/17309861
  vec3 hsl2rgb(vec3 col){
    const float onethird = 1.0 / 3.0;
    const float twothird = 2.0 / 3.0;
    const float rcpsixth = 6.0;

    float hue = col.x;
    float sat = col.y;
    float lum = col.z;

    vec3 xt = vec3(
        rcpsixth * (hue - twothird),
        0.0,
        rcpsixth * (1.0 - hue)
    );

    if (hue < twothird) {
        xt.r = 0.0;
        xt.g = rcpsixth * (twothird - hue);
        xt.b = rcpsixth * (hue      - onethird);
    }

    if (hue < onethird) {
        xt.r = rcpsixth * (onethird - hue);
        xt.g = rcpsixth * hue;
        xt.b = 0.0;
    }

    xt = min( xt, 1.0 );

    float sat2   =  2.0 * sat;
    float satinv =  1.0 - sat;
    float luminv =  1.0 - lum;
    float lum2m1 = (2.0 * lum) - 1.0;
    vec3  ct     = (sat2 * xt) + satinv;

    vec3 rgb;
    if (lum >= 0.5)
         rgb = (luminv * ct) + lum2m1;
    else rgb =  lum    * ct;

    return vec3(rgb);
  }

  // Main function that takes HSL values and returns the color name
  // 0 -> "cyan/aqua"
  // 1 -> "blue"
  // 2 -> "purple"
  // 3 -> "magenta"
  // 4 -> "red"
  // 5 -> "orange"
  // 6 -> "yellow"
  // 7 -> "green"
  int hsl2color(vec3 hsl) {

    float x = hsl.x * 360.0;

    if(x < 10.0){
      return 4;
    }
    else if(x < 40.0){
      return 5;
    }
    else if(x < 70.0){
      return 6;
    }
    else if(x < 160.0){
      return 7;
    }
    else if(x < 190.0){
      return 0;
    }
    else if(x < 250.0){
      return 1;
    }
    else if(x < 280.0){
      return 2;
    }
    else if(x < 340.0){
      return 3;
    }
    return 4;
  }


  float getColorIntensity(float value, float lowerBound, float lowerTarget, float upperTarget, float upperBound) {

    if(value >= lowerTarget && value <= upperTarget){
      return 1.0;
    }

    if(value <= lowerBound || value >= upperBound){
      return 0.0;
    }

    if(value <= lowerTarget){
      float range = lowerTarget - lowerBound;
      float point = value - lowerBound;
      if(range == 0.0){
        // if hue is red
        return 1.0;
      }
      return point  / range;
    }else if(value >= upperTarget){

      float range = upperBound - upperTarget;
      float point = value - upperTarget;
      return 1.0 - (point / range);
    }

    return 0.0;
  }


  // Main function that takes HSL values and returns the color name
  // 0 -> "cyan/aqua"
  // 1 -> "blue"
  // 2 -> "purple"
  // 3 -> "magenta"
  // 4 -> "red"
  // 5 -> "orange"
  // 6 -> "yellow"
  // 7 -> "green"
  void setColorIntensities(vec3 hsl, inout float intensities[8]) {

    float x = hsl.x * 360.0;

    float lowerRedIntensity = getColorIntensity(x, 0.0, 0.0, 5.0, 15.0); // 0 to 10
    float upperRedIntensity = getColorIntensity(x, 320.0, 340.0, 360.0, 360.0); // 340 to 360

    float redIntensity = lowerRedIntensity;
    if(upperRedIntensity > lowerRedIntensity){
      redIntensity = upperRedIntensity;
    }

    float orangeIntensity = getColorIntensity(x, 5.0, 15.0, 35.0, 50.0); // 10 to 40
    float yellowIntensity = getColorIntensity(x, 35.0, 50.0, 60.0, 90.0); // 40 to 70
    float greenIntensity = getColorIntensity(x, 60.0, 90.0, 130.0, 180.0); // 70 to 160
    float cyanIntensity = getColorIntensity(x, 130.0, 180.0, 185.0, 190.0); // 160 to 190
    float blueIntensity = getColorIntensity(x, 185.0, 190.0, 245.0, 260.0); // 190 to 250
    float purpleIntensity = getColorIntensity(x, 245.0, 260.0, 280.0, 300.0); // 250 to 280
    float magentaIntensity = getColorIntensity(x, 280.0, 300.0, 320.0, 340.0); // 280 to 340

    intensities[0] = cyanIntensity;
    intensities[1] = blueIntensity;
    intensities[2] = purpleIntensity;
    intensities[3] = magentaIntensity;
    intensities[4] = redIntensity;
    intensities[5] = orangeIntensity;
    intensities[6] = yellowIntensity;
    intensities[7] = greenIntensity;
  }

  mat4 saturationMatrix( float saturation ) {
    vec3 luminance = vec3(0.2126, 0.7152, 0.0722);
    float oneMinusSat = 1.0 - saturation;
    vec3 red = vec3( luminance.x * oneMinusSat );
    red.r += saturation;

    vec3 green = vec3( luminance.y * oneMinusSat );
    green.g += saturation;

    vec3 blue = vec3( luminance.z * oneMinusSat );
    blue.b += saturation;

    return mat4(
        red,     0,
        green,   0,
        blue,    0,
        0, 0, 0, 1
    );
  }

  // Y'UV (BT.709) to linear RGB
  // Values are from https://en.wikipedia.org/wiki/YUV
  vec3 yuv2rgb(vec3 yuv)
  {
      mat3 m = mat3(+1.00000, +1.00000, +1.00000,  // 1st column
                    +0.00000, -0.21482, +2.12798,  // 2nd column
                    +1.28033, -0.38059, +0.00000); // 3rd column
      return m * yuv;
  }

  // Linear RGB to Y'UV (BT.709)
  // Values are from https://en.wikipedia.org/wiki/YUV
  vec3 rgb2yuv(vec3 rgb)
  {
      mat3 m = mat3(+0.21260, -0.09991, +0.61500,  // 1st column
                    +0.71520, -0.33609, -0.55861,  // 2nd column
                    +0.07220, +0.43600, -0.05639); // 3rd column
      return m * rgb;
  }

  vec3 rgb2hsv(vec3 col){
    float R = col.r;
    float G = col.g;
    float B = col.b;

    float Xmax = _max(R, G, B);
    float V = Xmax;
    float Xmin = _min(R, G, B);
    float C = Xmax - Xmin;
    float L = (Xmax + Xmin) / 2.;

    float H;
    if (C == 0.)
      H = 0.;
    else if (V == R)
      H = 60. * mod((G - B) / C, 6.);
    else if (V == G)
      H = 60. * ((B - R) / C + 2.);
    else
      H = 60. * ((R - G) / C + 4.);

    float SV = V == 0. ? 0. : C / V;

    return vec3(H, SV, V);
  }

  vec3 hsv2rgb(vec3 col){
    float H = col.x;
    float SV = col.y;
    float V = col.z;

    float C = V * SV;

    float HP = H / 60.;

    float X = C * (1. - abs(mod(HP, 2.) - 1.));

    vec3 col1;
    if (HP >= 0. && HP < 1.)
      col1 = vec3(C, X, 0.);
    else if (HP >= 1. && HP < 2.)
      col1 = vec3(X, C, 0.);
    else if (HP >= 2. && HP < 3.)
      col1 = vec3(0., C, X);
    else if (HP >= 3. && HP < 4.)
      col1 = vec3(0., X, C);
    else if (HP >= 4. && HP < 5.)
      col1 = vec3(X, 0., C);
    else
      col1 = vec3(C, 0., X);

    float m = V - C;

    return vec3(col1.r + m, col1.g + m, col1.b + m);
  }

  float rgb2c(vec3 col)
  {
    float R = col.r;
    float G = col.g;
    float B = col.b;

    float Xmax = _max(R, G, B);
    float Xmin = _min(R, G, B);
    float C = Xmax - Xmin + .000001;

    return C;
  }

  vec4 adjustGrain(vec4 texColor, vec2 texCoord){
    // Convert the pixel position to a random value using the random function
    if(uGrainAmount == 0.0){
      return texColor;
    }
    float randomValue = random(dot(texCoord, vec2(12.9898, 78.233)), uGrainSeed);

    // Add grain to the color
    if(randomValue > uGrainRoughness){
      texColor.rgb += uGrainAmount * uGrainSize;
    }
    // texColor.rgb += uGrainAmount * smoothstep(0.0, uGrainRoughness, randomValue) * uGrainSize;

    return texColor;
  }

  vec3 adjustTemperature(vec3 color, float temperature, float tint){
    float scale = 0.0833;
    return clamp(yuv2rgb(rgb2yuv(color) + temperature * scale * vec3(0.0, -1.0, 1.0) + tint * scale * vec3(0.0, 1.0, 1.0)), 0.0, 1.0);
  }

  vec3 adjustHighlights(vec3 source){
    const mediump vec3 luminanceWeighting = vec3(0.2125, 0.7154, 0.0721);

    mediump float luminance = dot(source.rgb, luminanceWeighting);

    //(uShadows+1.0) changed to just uShadows:
    mediump float shadow = clamp((pow(luminance, 1.0/uShadows) + (-0.76)*pow(luminance, 2.0/uShadows)) - luminance, 0.0, 1.0);
    mediump float highlight = clamp((1.0 - (pow(1.0-luminance, 1.0/(2.0-uHighlights)) + (-0.8)*pow(1.0-luminance, 2.0/(2.0-uHighlights)))) - luminance, -1.0, 0.0);
    lowp vec3 result = vec3(0.0, 0.0, 0.0) + ((luminance + shadow + highlight) - 0.0) * ((source.rgb - vec3(0.0, 0.0, 0.0))/(luminance - 0.0));

    // blend toward white if uHighlights is more than 1
    mediump float contrastedLuminance = ((luminance - 0.5) * 1.5) + 0.5;
    mediump float whiteInterp = contrastedLuminance*contrastedLuminance*contrastedLuminance;
    mediump float whiteTarget = clamp(uHighlights, 1.0, 2.0) - 1.0;
    result = mix(result, vec3(1.0), whiteInterp*whiteTarget);

    // blend toward black if uShadows is less than 1
    mediump float invContrastedLuminance = 1.0 - contrastedLuminance;
    mediump float blackInterp = invContrastedLuminance*invContrastedLuminance*invContrastedLuminance;
    mediump float blackTarget = 1.0 - clamp(uShadows, 0.0, 1.0);
    result = mix(result, vec3(0.0), blackInterp*blackTarget);

    return result;
  }

  vec3 adjustHSL(vec3 color){
    // Convert RGB to HSL
    vec3 hsl = rgb2hsl(color.rgb);

    // Map the hue value to a specific color range
    int hueIndex = hsl2color(hsl);

    float hueAdjustment = 0.0;
    float saturationAdjustment = 0.0;
    float luminanceAdjustment = 0.0;

    float intensities[8];
    setColorIntensities(hsl, intensities);

    // for loop is to allow accessing arrays via an index
    for(int i = 0; i < 8; i++){
        float intensity = intensities[i];
        hueAdjustment = uHueAdjustments[i] * intensity;
        saturationAdjustment = uSaturationAdjustments[i] * intensity;
        luminanceAdjustment = uLuminanceAdjustments[i] * intensity;

        // Apply adjustments
        hsl.x += hueAdjustment;

        // ignore grays when increasing saturation
        if(hsl.y > 0.2 || saturationAdjustment < 0.0){
          hsl.y += saturationAdjustment;
          hsl.z += luminanceAdjustment;
        }

    }


    // Clamp values to valid ranges
    hsl.x = fract(hsl.x); // Ensure hue remains within [0, 1]
    hsl.y = clamp(hsl.y, 0.0, 1.0); // Ensure saturation stays within [0, 1]
    hsl.z = clamp(hsl.z, 0.0, 1.0); // Ensure luminance stays within [0, 1]


    // Convert HSL back to RGB

    color.rgb = hsl2rgb(hsl);
    return color;
  }

  // https://www.shadertoy.com/view/llGSzK
  vec3 adjustVibrance(vec3 inCol, float vibrance) //r,g,b 0.0 to 1.0,  vibrance 1.0 no change, 0.0 image B&W.
  {
      //float rf, gf, bf;

      //rf = *r;
      //gf = *g;
      //bf = *b;
   	  vec3 outCol;
      if (vibrance <= 1.0)
      {
          float avg = (inCol[0]*0.3 + inCol[1]*0.6 + inCol[2]*0.1);
          //outCol = inCol * avg;
          outCol[0] = inCol[0] * vibrance + avg*(1.0 - vibrance);
          outCol[1] = inCol[1] * vibrance + avg*(1.0 - vibrance);
          outCol[2] = inCol[2] * vibrance + avg*(1.0 - vibrance);
      }
      else // vibrance > 1.0
      {
          float hue_a, a, f, p1, p2, p3, i, h, s, v, amt, _max, _min, dlt;
          float br1, br2, br3, br4, br5, br2_or_br1, br3_or_br1, br4_or_br1, br5_or_br1;
          int use;

          _min = min(min(inCol[0], inCol[1]), inCol[2]);
          _max = max(max(inCol[0], inCol[1]), inCol[2]);
          dlt = _max - _min + 0.00001 /*Hack to fix divide zero infinities*/;
          h = 0.0;
          v = _max;

  		    br1 = 1.0 - float(_max > 0.0);
          s = (dlt / _max)*(1.0 - br1);
          h = -1.0*br1;

          br2 = float((_max - inCol[0]) > 0.0);
          br2_or_br1 = max(br2, br1);
          h = ((inCol[1] - inCol[2]) / dlt)*(1.0 - br2_or_br1) + (h*br2_or_br1);

          br3 = float((_max - inCol[1]) > 0.0);


          br3_or_br1 = max(br3, br1);
          h = (2.0 + (inCol[2] - inCol[0]) / dlt)*(1.0 - br3_or_br1) + (h*br3_or_br1);

          br4 = 1.0 - br2*br3;
          br4_or_br1 = max(br4, br1);
          h = (4.0 + (inCol[0] - inCol[1]) / dlt)*(1.0 - br4_or_br1) + (h*br4_or_br1);

          h = h*(1.0 - br1);


          hue_a = abs(h); // between h of -1 and 1 are skin tones
          a = dlt;      // Reducing enhancements on small rgb differences

          a = float(hue_a < 1.0)*a*(hue_a*0.67 + 0.33) + float(hue_a >= 1.0)*a;       //Reduce the enhancements on skin tones.
          a *= (vibrance - 1.0);
          s = (1.0 - a) * s + a*pow(s, 0.25);

          i = floor(h);
          f = h - i;

          p1 = v * (1.0 - s);
          p2 = v * (1.0 - (s * f));
          p3 = v * (1.0 - (s * (1.0 - f)));

          inCol[0] = inCol[1] = inCol[2] = 0.0;
          i += 6.0;
          //use = 1 << ((int)i % 6);
          use = int(pow(2.0,mod(i,6.0)));
          a = float(and(use , 1)); // i == 0;
          use /= 2;
          inCol[0] += a*v;
          inCol[1] += a*p3;
       	  inCol[2] += a*p1;

          a = float(and(use , 1)); // i == 1;
          use /=2;
          inCol[0] += a*p2;
          inCol[1] += a*v;
          inCol[2] += a*p1;

          a = float( and(use,1)); // i == 2;
          use /=2;
          inCol[0] += a*p1;
          inCol[1] += a*v;
          inCol[2] += a*p3;

          a = float(and(use, 1)); // i == 3;
          use /= 2;
          inCol[0] += a*p1;
          inCol[1] += a*p2;
          inCol[2] += a*v;

          a = float(and(use, 1)); // i == 4;
          use /=2;
          inCol[0] += a*p3;
          inCol[1] += a*p1;
          inCol[2] += a*v;

          a = float(and(use, 1)); // i == 5;
          use /=2;
          inCol[0] += a*v;
          inCol[1] += a*p1;
          inCol[2] += a*p2;

          outCol = inCol;
          //*r = rf;
          //*g = gf;
          //*b = bf;
      }
      return outCol;
  }

  vec4 adjustSaturation(vec4 texColor, float value){
    vec3 hsl = rgb2hsl(texColor.rgb);
    return saturationMatrix(value) * texColor;
  }

  vec3 adjustExposure(vec3 color, float value) {
    return color * (1.0 + value);
  }

  vec3 adjustGamma(vec3 color, float gamma) {
    return pow(color, vec3(1.0 / gamma));
  }

  vec3 adjustContrast(vec3 color, float value){
    float contrastF = 1.015 * (value + 1.0) / (1.0 * (1.015 - value));
    color = contrastF * (color - 0.5) + 0.5;
    return color;
  }

  vec3 adjustBlacksAndWhites(vec3 color, float blacksAdjustment, float whitesAdjustment){

    float gray = 0.33 * (color.r + color.g + color.b);
    float whitesThreshold = 0.3;
    float blacksThreshold = 0.9;
    if(gray > whitesThreshold){
      float scalar = (gray * 2.0) - 0.5;
      color += vec3(whitesAdjustment * scalar);
    }

    if(gray < blacksThreshold){
      float scalar = 1.0 - (gray / blacksThreshold);
      color += vec3(blacksAdjustment * scalar);
    }

    return color;
  }
`

const FRAGMENT_SOURCE = `precision highp float;
  uniform sampler2D uTexture;
  varying vec2 vTexCoord;

  uniform float uWhites;
  uniform float uBlacks;
  uniform float uContrast;
  uniform float uExposure;
  uniform float uVibrance;
  uniform float uSaturation;
  uniform float uGamma;

  uniform float uHighlights;
  uniform float uShadows;

  uniform float uTemperature;
  uniform float uTint;

  uniform float uGrainAmount;
  uniform float uGrainSize;
  uniform float uGrainRoughness;
  uniform float uGrainSeed;

  uniform float uHueAdjustments[8];
  uniform float uSaturationAdjustments[8];
  uniform float uLuminanceAdjustments[8];

  ${HELPER_FUNCTIONS}

  void main() {
    vec4 texColor = texture2D(uTexture, vTexCoord);

    texColor.rgb = adjustHighlights(texColor.rgb);
    texColor.rgb = adjustExposure(texColor.rgb, uExposure);
    texColor.rgb = adjustGamma(texColor.rgb, uGamma);

    texColor.rgb = adjustContrast(texColor.rgb, uContrast);
    texColor.rgb = adjustBlacksAndWhites(texColor.rgb, uBlacks, uWhites);
    texColor.rgb = adjustVibrance(texColor.rgb, uVibrance);

    texColor.rgb = adjustHSL(texColor.rgb);

    texColor.rgb = adjustTemperature(texColor.rgb, uTemperature, uTint);

    texColor = adjustSaturation(texColor, uSaturation);

    texColor = adjustGrain(texColor, vTexCoord);

    gl_FragColor = vec4(texColor.rgb, texColor.a);
  }
`

fabric.Image.filters.Lightroom = fabric.util.createClass(fabric.Image.filters.BaseFilter, {

  name: "None",

  type: 'Lightroom',

  initialize: function(options) {
    this.callSuper('initialize', options);

    this.whites = 0
    this.blacks = 0
    this.contrast = 0
    this.exposure = 0
    this.vibrance = 0
    this.saturation = 0
    this.gamma = 0

    this.highlights = 0
    this.shadows = 0

    this.temperature = 0
    this.tint = 0

    this.grainAmount = 0
    this.grainSize = 0
    this.grainRoughness = 0
    this.grainSeed = 1500861553

    for(var i =0; i < COLORS.length; i++){
      let color = COLORS[i]
      let colorLowerCase = color.toLowerCase()

      this[`${colorLowerCase}HueAdjustment`] = 0
      this[`${colorLowerCase}SaturationAdjustment`] = 0
      this[`${colorLowerCase}LuminanceAdjustment`] = 0
    }
  },

  /**
   * Fragment source for the Exposure program
   * https://timseverien.com/posts/2020-06-19-colour-correction-with-webgl/
   */
  fragmentSource: FRAGMENT_SOURCE,

  applyTo2d: function(options) {
    if (this.intensity === 0) {
      return;
    }

    var imageData = options.imageData, data = imageData.data, i, len = data.length;

    for (i = 0; i < len; i += 4) {
      // TODO implement

    }

  },

  /**
    * Return WebGL uniform locations for this filter's shader.
    *
    * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
    * @param {WebGLShaderProgram} program This filter's compiled shader program.
    */
   getUniformLocations: function(gl, program) {

     return {
       uWhites: gl.getUniformLocation(program, 'uWhites'),
       uBlacks: gl.getUniformLocation(program, 'uBlacks'),
       uContrast: gl.getUniformLocation(program, 'uContrast'),
       uExposure: gl.getUniformLocation(program, 'uExposure'),
       uVibrance: gl.getUniformLocation(program, 'uVibrance'),
       uSaturation: gl.getUniformLocation(program, 'uSaturation'),
       uGamma: gl.getUniformLocation(program, 'uGamma'),

       uHighlights: gl.getUniformLocation(program, 'uHighlights'),
       uShadows: gl.getUniformLocation(program, 'uShadows'),

       uTemperature: gl.getUniformLocation(program, 'uTemperature'),
       uTint: gl.getUniformLocation(program, 'uTint'),

       uGrainAmount: gl.getUniformLocation(program, 'uGrainAmount'),
       uGrainSize: gl.getUniformLocation(program, 'uGrainSize'),
       uGrainRoughness: gl.getUniformLocation(program, 'uGrainRoughness'),
       uGrainSeed: gl.getUniformLocation(program, 'uGrainSeed'),

       uHueAdjustments: gl.getUniformLocation(program, 'uHueAdjustments'),
       uSaturationAdjustments: gl.getUniformLocation(program, 'uSaturationAdjustments'),
       uLuminanceAdjustments: gl.getUniformLocation(program, 'uLuminanceAdjustments'),
     }
   },

   /**
    * Send data from this filter to its shader program's uniforms.
    *
    * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
    * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
    */
   sendUniformData: function(gl, uniformLocations) {

     let intensity = this.intensity

     let whites = mapRange(this.whites, [-100, 100], [-0.15, 0.3])
     gl.uniform1f(uniformLocations.uWhites, whites * intensity);

     let blacks = 0
     if(this.blacks < 0){
       blacks = mapRange(this.blacks, [-100, 0], [-0.3, 0])
     }
     else{
       blacks = mapRange(this.blacks, [0, 100], [0, 0.05])
     }
     gl.uniform1f(uniformLocations.uBlacks, blacks * intensity);


     let contrast = mapRange(this.contrast, [-100, 100], [-0.2, 0.2], false)
     gl.uniform1f(uniformLocations.uContrast, contrast * intensity);


     let exposure = 0
     if(this.exposure < 0){
       exposure = mapRange(this.exposure, [-5, 0], [-0.95, 0])
     }
     else{
       exposure = mapRange(this.exposure, [0, 5], [0, 1.6])
     }
     gl.uniform1f(uniformLocations.uExposure, exposure * intensity);


     let vibrance = 0
     if(this.vibrance < 0){
       vibrance = mapRange(this.vibrance, [-100, 0], [-0.8, 0])
     }
     else{
       vibrance = mapRange(this.vibrance, [0, 100], [0, 7])
     }
     gl.uniform1f(uniformLocations.uVibrance, 1 + vibrance * intensity);


     let saturation = 0
     if(this.saturation < 0){
       saturation = mapRange(this.saturation, [-100, 0], [-1, 0])
     }
     else{
       saturation = mapRange(this.saturation, [0, 100], [0, 2.0])
     }
     gl.uniform1f(uniformLocations.uSaturation, 1 + (saturation * intensity));


     gl.uniform1f(uniformLocations.uGamma, 1 + (this.gamma * intensity));


     let highlights = 0
     if(this.highlights < 0){
       highlights = mapRange(this.highlights, [-100, 0], [-1, 0])
     }
     else{
       highlights = mapRange(this.highlights, [0, 100], [0, 1])
     }
     gl.uniform1f(uniformLocations.uHighlights, 1 + (highlights * intensity));

     let shadows = 0
     if(this.shadows < 0){
       shadows = mapRange(this.shadows, [-100, 0], [-1, 0])
     }
     else{
       shadows = mapRange(this.shadows, [0, 100], [0, 1])
     }
     gl.uniform1f(uniformLocations.uShadows, 1 + (shadows * intensity));


     let temperature = mapRange(this.temperature, [-100, 100], [-1, 1])
     gl.uniform1f(uniformLocations.uTemperature, temperature * intensity);

     let tint = mapRange(this.tint, [-100, 100], [-0.5, 0.5])
     gl.uniform1f(uniformLocations.uTint, tint * intensity);

     let grainAmount = mapRange(this.grainAmount, [0, 100], [0, 0.5], false);
     gl.uniform1f(uniformLocations.uGrainAmount, grainAmount * intensity);

     let grainSize = mapRange(this.grainSize, [0, 100], [1, 0.1], false);
     gl.uniform1f(uniformLocations.uGrainSize, grainSize);

     let grainRoughness = mapRange(this.grainRoughness, [0, 100], [0.90, 0.96], false);
     gl.uniform1f(uniformLocations.uGrainRoughness, grainRoughness);

     gl.uniform1f(uniformLocations.uGrainSeed, this.grainSeed);

     let hueAdjustments = []
     let saturationAdjustments = []
     let luminanceAdjustments = []

     for(var i=0; i < COLORS.length; i++){
       let color = COLORS[i]
       let colorLowerCase = color.toLowerCase()

       let hueAdjustment = mapRange(this[`${colorLowerCase}HueAdjustment`], [-100, 100], [-0.083, 0.083], false);
       hueAdjustments.push(hueAdjustment * intensity)


       let saturationAdjustment = this[`${colorLowerCase}SaturationAdjustment`]
       if(saturationAdjustment < 0){
         saturationAdjustment = mapRange(saturationAdjustment, [-100, 0], [-1, 0]);
       }else{
         saturationAdjustment = mapRange(saturationAdjustment, [0, 100], [0, 0.40]);
       }
       saturationAdjustments.push(saturationAdjustment * intensity)


       let luminanceAdjustment = this[`${colorLowerCase}LuminanceAdjustment`]
       if(luminanceAdjustment < 0){
         luminanceAdjustment = mapRange(luminanceAdjustment, [-100, 0], [-0.02, 0]);
       }else{
         luminanceAdjustment = mapRange(luminanceAdjustment, [0, 100], [0, 0.2]);
       }
       luminanceAdjustments.push(luminanceAdjustment * intensity)
     }

     gl.uniform1fv(uniformLocations.uHueAdjustments, hueAdjustments);
     gl.uniform1fv(uniformLocations.uSaturationAdjustments, saturationAdjustments);
     gl.uniform1fv(uniformLocations.uLuminanceAdjustments, luminanceAdjustments);
   },
});

fabric.Image.filters.Lightroom.fromObject = fabric.Image.filters.BaseFilter.fromObject;

const Lightroom = fabric.Image.filters.Lightroom

export { Lightroom }

// https://math.stackexchange.com/a/377174
function mapRange(value, originalRange, newRange, shouldCompress=true){
  let x = value
  let a = originalRange[0]
  let b = originalRange[1]
  let c = newRange[0]
  let d = newRange[1]
  let adjustedValue = (x - a) * ( (d - c) / (b - a) ) + c
  if(!shouldCompress){
    return adjustedValue
  }
  return compress(adjustedValue, adjustedValue < 0 ? c : d)
}

function compress(value, max){
  let scalar = (value / max)
  let compressedValue = Math.abs(value * scalar * scalar)
  return value < 0 ? -compressedValue : compressedValue
}
