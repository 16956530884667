import React, { Component } from "react";

import moment from "moment";

export class Footer extends Component {
  render() {
    return (
      <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted fw-bold me-1">
              {moment().format("YYYY")}©
            </span>
            <a
              href=""
              target="_blank"
              className="text-gray-800 text-hover-primary"
            >
              LA Edit
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
