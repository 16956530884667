import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import DashboardApp from "./DashboardApp";

export default class App extends React.Component {
  render() {
    return (
      
      <>
        <Switch>
          <Route path="/" component={DashboardApp} />
        </Switch>
      </>
    );
  }
}
