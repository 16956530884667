import FetchHelper from "./FetchHelper";

import AuthManager from "./AuthManager";


export default class Backend {
  static getStats() {
    return FetchHelper.get(`${window.Api.Stats}?monthly_stats=true&this_year=true&this_week=true`);
  }

  static getDownloadsStats() {
    return FetchHelper.get(
      `${window.Api.Stats}?daily_downloads=true`
    );
  }

  static getUnsubscriptionsStats() {
    return FetchHelper.get(
      `${window.Api.Stats}?daily_unsubscriptions=true`
    );
  }

  static getRevenueStats() {
    return FetchHelper.get(
      `${window.Api.Stats}?daily_revenue=true`
    );
  }

  static getPopularMonthlyFilters() {
    return FetchHelper.get(
      `${window.Api.Stats}?monthly_popular_filters=true`
    );
  }

  static getPopularMonthlyTools() {
    return FetchHelper.get(
      `${window.Api.Stats}?monthly_popular_tools=true`
    );
  }

  static updateAccount(admin) {
    let data = { ...admin };
    return FetchHelper.patch(`${window.Api.Admins}/${admin.user.id}`, data);
  }
}
