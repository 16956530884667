import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.Cocktail = fabric.util.createClass(
  fabric.Image.filters.Lightroom,
  {
    name: "Cocktail",

    type: "Cocktail",

    initialize: function (options) {
      this.callSuper("initialize", options);

      this.whites = -2;
      this.blacks = 31;
      this.contrast = -6;
      this.exposure = -0.15;
      this.vibrance = 49;
      this.saturation = 8;
      this.highlights = -38;
      this.shadows = 45;
      this.temperature = 3;
      this.aquaHueAdjustment = 8;
      this.aquaSaturationAdjustment = 25;
      this.aquaLuminanceAdjustment = 17;
      this.blueHueAdjustment = -14;
      this.blueSaturationAdjustment = 3;
      this.blueLuminanceAdjustment = -28;
      this.purpleLuminanceAdjustment = 12;
      this.magentaLuminanceAdjustment = -20;
      this.redHueAdjustment = 12;
      this.redSaturationAdjustment = 10;
      this.redLuminanceAdjustment = 53;
      this.orangeHueAdjustment = -7;
      this.orangeLuminanceAdjustment = -17;
      this.yellowHueAdjustment = 11;
      this.yellowSaturationAdjustment = 14;
      this.yellowLuminanceAdjustment = 12;
      this.greenHueAdjustment = 3;
      this.greenSaturationAdjustment = -37;
      this.greenLuminanceAdjustment = -45;
      this.intensity = 0.8;
    },
  }
);

fabric.Image.filters.Cocktail.fromObject =
  fabric.Image.filters.Lightroom.fromObject;

const Cocktail = fabric.Image.filters.Cocktail;

export { Cocktail };
