import React, { useState, useEffect, useRef } from "react";

import { fabric } from "fabric";

import { DisplayFilters } from "./filters/index";

const FilterThumbnail = (props) => {
  let { image, filter } = props;

  const [canvas, setCanvas] = useState(null);

  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const thumbnailCanvas = new fabric.StaticCanvas(canvasRef.current, {
      width: 100,
      height: 100,
      borderRadius: "25%",
    });

    thumbnailCanvas.renderAll();

    setCanvas(thumbnailCanvas);
  }, []);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    fabric.Image.fromURL(
      image,
      (oImg) => {
        oImg.lockMovementX = true;
        oImg.lockMovementY = true;
        oImg.selectable = false;

        canvas.add(oImg);

        let fabricFilter = DisplayFilters.find((o) => o.name === filter);

        fabricFilter.intensity = 1;

        oImg.filters = [fabricFilter];
        oImg.applyFilters();

        oImg.scaleToWidth(100);
        oImg.scaleToHeight(100);

        canvas.renderAll();
      },
      { crossOrigin: "anonymous" }
    );

    canvas.requestRenderAll();
  }, [canvas, image, filter]);

  return (
    <div>
      <canvas ref={canvasRef} style={{ borderRadius: "25%" }} />
    </div>
  );
};

export default FilterThumbnail;
