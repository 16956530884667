import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import General from "../../../../utils/General";

import BaseTable from "../BaseTable";

class LatestUsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderDate(dateText, date) {
    if (this.props.text === dateText && date) {
      return moment(date).format("DD MMM YYYY");
    } else if (this.props.text === dateText && !date) {
      return "-";
    }
  }

  _getColumns() {
    let dateText = "Date";

    if (this.props.text === "Subscribed On") {
      dateText = "Subscribed On";
    } else if (this.props.text === "Unsubscribed On") {
      dateText = "Unsubscribed On";
    }

    return [
      {
        Header: () => (
          <span className="p-0 text-muted fw-bolder">Name & Email</span>
        ),
        id: "user",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let customer = rowInfo.original;

          let customerName = General.toTitleCase(
            `${customer.user?.first_name} ${customer.user?.last_name}`
          );

          let customerEmail = customer.user?.email;

          return (
            <>
              <a
                href="javascript:;"
                className="text-dark fw-bolder text-hover-primary fs-6"
              >
                {customerName || "-"}
              </a>
              <a
                href="javascript:;"
                className="text-muted text-hover-primary fw-bold text-muted d-block fs-7"
              >
                {customerEmail && customerEmail}
              </a>
            </>
          );
        },
      },
      {
        Header: () => (
          <span className="p-0 min-w-150px text-muted fw-bolder">
            Version Downloaded
          </span>
        ),
        id: "subscription.version",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let customer = rowInfo.original;

          let customerSubscriptionVersion = customer?.subscription?.version;

          if (!customerSubscriptionVersion) {
            return "-";
          }

          return (
            <span className="text-dark fw-bold">
              {customerSubscriptionVersion}
            </span>
          );
        },
      },
      {
        Header: () => (
          <span className="p-0 min-w-200px text-muted fw-bolder">
            Membership
          </span>
        ),
        id: "subscription.price.frequency",
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let customer = rowInfo.original;

          let subscriptionFrequency = customer.subscription?.title

          if (!subscriptionFrequency) {
            return "-";
          }

          return (
            <span className="text-dark fw-bold">
              {General.toTitleCase(subscriptionFrequency)}
            </span>
          );
        },
      },
      {
        Header: () => (
          <div className="text-end">
            <span className="p-0 min-w-100px text-muted fw-bolder">
              {dateText}
            </span>
          </div>
        ),
        id: "subscription",
        exportable: false,
        Cell: (rowInfo) => {
          let customer = rowInfo.original;

          let createdAt = customer.user?.created_at;

          let subscribedAt = customer?.subscription?.subscribed_at;

          let unsubscribedAt = customer?.subscription?.unsubscribed_at;

          return (
            <div className="text-end">
              <span className="text-dark fw-bolder">
                {this._renderDate("Date", createdAt)}

                {this._renderDate("Subscribed On", subscribedAt)}

                {this._renderDate("Unsubscribed On", unsubscribedAt)}
              </span>
            </div>
          );
        },
      },
      { Header: "First Name", id: "user.first_name", show: false },
      { Header: "Last Name", id: "user.last_name", show: false },
      { Header: "Email", id: "user.email", show: false },
      { Header: "Email Verified", id: "user.email_verified", show: false },
      { Header: "Membership", id: "subscription.title", show: false },
      { Header: "Type", id: "type", show: false },
      {
        Header: "Subscribed At",
        id: "subscription.subscribed_at",
        show: false,
        format: "date",
      },
      {
        Header: "Unsubscribed At",
        id: "subscription.unsubscribed_at",
        show: false,
        format: "date",
      },
      {
        Header: "Last Seen At",
        id: "user.last_login",
        show: false,
        format: "date",
      },
      {
        Header: "Signed Up At",
        id: "user.created_at",
        show: false,
        format: "date",
      },
    ];
  }

  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    let endpoint = window.Api.Customers;
    if (this.props.text === "Subscribed On") {
      endpoint += "?subscribed=true";
    } else if (this.props.text === "Unsubscribed On") {
      endpoint += "?subscribed=false";
    }

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={endpoint}
          noDataMessage={"No Latest Users Found."}
          columns={columns}
          showPagination={true}
          showSearch={false}
          showExport={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
      </>
    );
  }
}

LatestUsersTable.defaultProps = {
  title: "",
  text: "Date",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(LatestUsersTable);
