import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.Cayman = fabric.util.createClass(
  fabric.Image.filters.Lightroom,
  {
    name: "Cayman",

    type: "Cayman",

    initialize: function (options) {
      this.callSuper("initialize", options);

      this.whites = -62;
      this.blacks = -38;
      this.contrast = -14;
      this.exposure = -2.75;
      this.vibrance = -13;
      this.saturation = -37;
      this.highlights = -31;
      this.shadows = -11;
      this.temperature = 59;
      this.tint = -33;
      this.grainAmount = 10;
      this.aquaHueAdjustment = -6;
      this.aquaSaturationAdjustment = -5;
      this.aquaLuminanceAdjustment = 49;
      this.blueHueAdjustment = -5;
      this.blueSaturationAdjustment = 32;
      this.blueLuminanceAdjustment = -10;
      this.purpleSaturationAdjustment = 18;
      this.magentaSaturationAdjustment = -54;
      this.redHueAdjustment = -30;
      this.redSaturationAdjustment = -19;
      this.redLuminanceAdjustment = -72;
      this.orangeHueAdjustment = -26;
      this.orangeSaturationAdjustment = -38;
      this.orangeLuminanceAdjustment = -81;
      this.yellowHueAdjustment = -2;
      this.yellowSaturationAdjustment = -39;
      this.yellowLuminanceAdjustment = -13;
      this.greenHueAdjustment = -17;
      this.greenSaturationAdjustment = -54;
      this.greenLuminanceAdjustment = -50;
      this.intensity = 1;
    },
  }
);

fabric.Image.filters.Cayman.fromObject =
  fabric.Image.filters.Lightroom.fromObject;

const Cayman = fabric.Image.filters.Cayman;

export { Cayman };
