import { Lightroom } from "./Lightroom"

import { fabric } from 'fabric';

fabric.Image.filters.Cabin = fabric.util.createClass(fabric.Image.filters.Lightroom, {

  name: "Cabin",
  
  type: 'Cabin',

  initialize: function(options) {
    this.callSuper('initialize', options);
    
    this.whites = -15 
    this.blacks = -54 
    this.contrast = 8 
    this.exposure = -0.2 
    this.vibrance = -18 
    this.saturation = -21 
    this.highlights = -25 
    this.temperature = 74 
    this.tint = 42 
    this.redHueAdjustment = 1 
    this.redSaturationAdjustment = 28 
    this.orangeHueAdjustment = -5 
    this.orangeSaturationAdjustment = -31
    this.orangeLuminanceAdjustment = -12 
    this.yellowSaturationAdjustment = -22 
    this.intensity = 1 
  },

});

fabric.Image.filters.Cabin.fromObject = fabric.Image.filters.Lightroom.fromObject;

const Cabin = fabric.Image.filters.Cabin

export { Cabin }
