import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.BlackAndWhiteV2 = fabric.util.createClass(
  fabric.Image.filters.Lightroom,
  {
    name: "Moonlight",

    type: "BlackAndWhiteV2",

    initialize: function (options) {
      this.callSuper("initialize", options);

      this.whites = 7
      this.blacks = 18
      this.contrast = 25
      this.exposure = -0.8
      this.vibrance = -1
      this.saturation = -100
      this.highlights = 14
      this.shadows = 38
      this.purpleLuminanceAdjustment = 4
      this.magentaLuminanceAdjustment = 5
      this.redLuminanceAdjustment = -4
      this.orangeSaturationAdjustment = -4
      this.orangeLuminanceAdjustment = -2
      this.yellowLuminanceAdjustment = -14
      this.greenLuminanceAdjustment = -4
      this.intensity = 1
    },
  }
);

fabric.Image.filters.BlackAndWhite.fromObject =
  fabric.Image.filters.Lightroom.fromObject;

const BlackAndWhiteV2 = fabric.Image.filters.BlackAndWhiteV2;

export { BlackAndWhiteV2 };
