
import { fabric } from 'fabric';

fabric.Image.filters.Highlights = fabric.util.createClass(fabric.Image.filters.BaseFilter, {

  type: 'Highlights',

  /**
   * Fragment source for the Highlight program
   * https://stackoverflow.com/questions/26511037/how-can-i-modify-this-webgl-fragment-shader-to-increase-brightness-of-highlights
   */
  fragmentSource: `precision highp float;
    uniform sampler2D uTexture;
    varying highp vec2 vTexCoord;

    uniform lowp float uShadows;
    uniform lowp float uHighlights;

    const mediump vec3 luminanceWeighting = vec3(0.2125, 0.7154, 0.0721);

    void main()
    {
      lowp vec4 source = texture2D(uTexture, vTexCoord);
      mediump float luminance = dot(source.rgb, luminanceWeighting);

      //(uShadows+1.0) changed to just uShadows:
      mediump float shadow = clamp((pow(luminance, 1.0/uShadows) + (-0.76)*pow(luminance, 2.0/uShadows)) - luminance, 0.0, 1.0);
      mediump float highlight = clamp((1.0 - (pow(1.0-luminance, 1.0/(2.0-uHighlights)) + (-0.8)*pow(1.0-luminance, 2.0/(2.0-uHighlights)))) - luminance, -1.0, 0.0);
      lowp vec3 result = vec3(0.0, 0.0, 0.0) + ((luminance + shadow + highlight) - 0.0) * ((source.rgb - vec3(0.0, 0.0, 0.0))/(luminance - 0.0));

      // blend toward white if uHighlights is more than 1
      mediump float contrastedLuminance = ((luminance - 0.5) * 1.5) + 0.5;
      mediump float whiteInterp = contrastedLuminance*contrastedLuminance*contrastedLuminance;
      mediump float whiteTarget = clamp(uHighlights, 1.0, 2.0) - 1.0;
      result = mix(result, vec3(1.0), whiteInterp*whiteTarget);

      // blend toward black if uShadows is less than 1
      mediump float invContrastedLuminance = 1.0 - contrastedLuminance;
      mediump float blackInterp = invContrastedLuminance*invContrastedLuminance*invContrastedLuminance;
      mediump float blackTarget = 1.0 - clamp(uShadows, 0.0, 1.0);
      result = mix(result, vec3(0.0), blackInterp*blackTarget);

      gl_FragColor = vec4(result, source.a);
    }`,

  /**
    * Return WebGL uniform locations for this filter's shader.
    *
    * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
    * @param {WebGLShaderProgram} program This filter's compiled shader program.
    */
   getUniformLocations: function(gl, program) {
     return {
       uShadows: gl.getUniformLocation(program, 'uShadows'),
       uHighlights: gl.getUniformLocation(program, 'uHighlights'),
     };
   },

   /**
    * Send data from this filter to its shader program's uniforms.
    *
    * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
    * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
    */
   sendUniformData: function(gl, uniformLocations) {
     gl.uniform1f(uniformLocations.uHighlights, this.highlights);
     gl.uniform1f(uniformLocations.uShadows, this.shadows || 1);
   },

   // Non WebGL
   applyTo2d: function(options) {
     if (this.highlights === 0) {
       return;
     }

     var imageData = options.imageData, data = imageData.data, i, len = data.length;


     for (i = 0; i < len; i += 4) {
       // make image green when WEBGL not supported
       data[i] = 0;
       data[i + 2] = 0;
     }

   },
});

fabric.Image.filters.Highlights.fromObject = fabric.Image.filters.BaseFilter.fromObject;

const Highlights = fabric.Image.filters.Highlights

export { Highlights }
