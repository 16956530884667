import React, { Component } from "react";

import moment from "moment";

import { Line } from "react-chartjs-2";

import ZoomPlugin from "chartjs-plugin-zoom";

import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Currency from "../utils/Currency";

import LatestUsersTable from "./components/tables/latest-users/LatestUsersTable";

import StatsCard from "./components/stats-cards/StatsCard";

export default class Downloads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statInfo: null,
      graphStats: null,
    };
  }

  componentDidMount() {
    this._getStats();
    this._getGraphStats();
  }

  _getGraphStats() {
    Backend.getDownloadsStats()
      .then((graphStats) => {
        this.setState({ graphStats, isLoading: false });
        let graphMap = graphStats?.stats?.daily_downloads;

        const date = graphMap?.map((statInfo) => statInfo?.date);

        const totalSubscriptions = graphMap?.map(
          (statInfo) => statInfo?.customer_count
        );

        let graphData = {
          labels: date,

          datasets: [
            {
              label: "Downloads",
              data: totalSubscriptions,
              fill: true,
              borderColor: "#FFB800",
              borderWidth: 4,
              pointDot: true,
              backgroundColor: "#FFEAB3",
            },
          ],
        };

        this.setState({
          graphs: graphData,
          isLoading: false,
        });

        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 300);
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _handleResetZoom() {
    const chart = this.chartRef.chartInstance;
    chart.resetZoom();
  }

  _renderGraphs() {
    let { graphs, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    const options = {
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            sensitivity: 0.5,
            drag: false,
            enabled: true,
            mode: "x",
          },
        },
      },
    };

    let filteredData = graphs?.datasets[0]?.data.map((value) =>
      Math.max(0, value)
    );
    if (filteredData) {
      graphs.datasets[0].data = filteredData;
    }

    return (
      <>
        <div className="reset-btn ml-5">
          <button
            className="btn btn-secondary"
            onClick={() => this._handleResetZoom()}
          >
            Reset Zoom
          </button>
        </div>
        <Line
          ref={(reference) => (this.chartRef = reference)}
          data={graphs}
          options={options}
          plugins={[ZoomPlugin]}
        />
      </>
    );
  }

  _getStats() {
    this.setState({ isLoading: true });

    Backend.getStats()
      .then((stats) => {
        this.setState({ statInfo: stats, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    let { statInfo } = this.state;

    if (!statInfo) {
      return null;
    }

    const currentYear = moment().year();
    const currentMonth = moment().month() + 1; 

    const currentMonthStat =  statInfo.stats?.monthly.find((stat) => {
      const [year, month] = stat.date.split("-").map(Number);
      return year === currentYear && month === currentMonth;
    });

    const weeklyStats = statInfo.stats?.week;

    return (
      <>
        <div className="container">
          <h1 className=" fw-bold ff-bitter mt-2 mb-10 fs-xl-1"> Downloads </h1>
          <div className="row wd-100">
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${weeklyStats?.lite_subscriber_count}`,
                    title: "Lite Downloads this week",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(11.959697, 18.661508) rotate(-270.000000) translate(-11.959697, -18.661508) "
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: currentMonthStat?.lite_subscriber_count,
                    title: "Lite Downloads this month",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(11.959697, 18.661508) rotate(-270.000000) translate(-11.959697, -18.661508) "
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(currentMonthStat?.revenue_lite)}`,
                    title: "Revenue from Lite this month",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,4.25932872 C12.1488635,4.25921584 12.3000368,4.29247316 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 L12,4.25932872 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M12,4.25932872 L12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.277344,4.464261 11.6315987,4.25960807 12,4.25932872 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="row wd-100">
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${weeklyStats?.pro_subscriber_count}`,
                    title: "Pro Downloads this week",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(11.959697, 18.661508) rotate(-270.000000) translate(-11.959697, -18.661508) "
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: currentMonthStat?.pro_subscriber_count,
                    title: "Pro Downloads this month",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(11.959697, 18.661508) rotate(-270.000000) translate(-11.959697, -18.661508) "
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(currentMonthStat?.revenue_pro)}`,
                    title: "Revenue from Pro this month",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.3476862,4.32173209 11.9473121,4.11839309 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="row wd-100">
            <div className="col-md-12">
              <div className="card card-custom gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Downloads Graph</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  {this._renderGraphs()}
                  <div id="projected_sales" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 mt-5">
            <LatestUsersTable title="Latest Users Signed Up" />
          </div>
        </div>
      </>
    );
  }
}
