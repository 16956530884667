import React, { Component } from "react";

import moment from "moment";

import General from "../../../utils/General";

import noFiltersImage from "../../../assets/media/svg/no-content/no-image.svg";

import FilterThumbnail from "../FilterThumbnail";

export default class MonthlyFavouriteCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popularMonthlyFilters: props.popularMonthlyFilters,
      popularMonthlyTools: props.popularMonthlyTools,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderNoContent() {
    const { popularMonthlyFilters } = this.state;

    const text = popularMonthlyFilters ? "filters" : "features";

    return (
      <>
        <div className="col">
          <h4 className="align-center">Oops, no {text} found</h4>
          <div className="d-flex">
            <img
              src={noFiltersImage}
              alt={text}
              width="16%"
              className="m-auto my-1"
            />
          </div>
        </div>
      </>
    );
  }

  _getFilteredMonthlyFavourite(popularMonthlyFavourite, startsAt) {
    return popularMonthlyFavourite
      ?.slice(3, 13)
      ?.filter(
        (popularMonthlyFilter) =>
          moment(popularMonthlyFilter.date).format("MMM") == startsAt
      );
  }

  _renderMonthlyFavouriteContent(filteredMonthlyFavourite) {
    return filteredMonthlyFavourite?.map((popularMonthlyFavourite) => {
      let feature =
        popularMonthlyFavourite.tools || popularMonthlyFavourite.filters;

      return feature?.slice(0, 5).map((monthlyFavourite, index) => {
        let imageSrc;

        if (feature == popularMonthlyFavourite.tools && monthlyFavourite) {
          switch (monthlyFavourite.toLowerCase()) {
            case "adjust":
              imageSrc = "assets/media/features-icons/adjust.svg";
              break;
            case "crop":
              imageSrc = "assets/media/features-icons/crop.svg";
              break;
            case "filter":
              imageSrc = "assets/media/features-icons/filter.svg";
              break;
            case "frame":
              imageSrc = "assets/media/features-icons/frame.svg";
              break;
            case "rotate":
              imageSrc = "assets/media/features-icons/rotate.svg";
              break;
            case "straighten":
              imageSrc = "assets/media/features-icons/straighten.svg";
              break;
            case "text":
              imageSrc = "assets/media/features-icons/text.svg";
              break;
            default:
              imageSrc = "";
              break;
          }
        }

        if (feature == popularMonthlyFavourite.filters && monthlyFavourite) {
          imageSrc = "assets/media/thumbs/01.png";
        }

        return (
          <div key={index}>
            <div className="symbol symbol-100px me-6 position-relative">
              {imageSrc ? (
                feature == popularMonthlyFavourite.filters ? (
                  <FilterThumbnail
                    image={imageSrc}
                    filter={monthlyFavourite}
                  />
                ) : (
                  <img className="rounded-25" src={imageSrc} alt="Thumbnail" />
                )
              ) : (
                <>
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      width: 100,
                      height: 100,
                      borderRadius: "25%",
                    }}
                  >
                    <h1 className="m-0 no-filter-card">
                      {General.getInitials(`${monthlyFavourite}`)}
                    </h1>
                  </div>
                </>
              )}
            </div>
            <div className="d-flex justify-content-start flex-column">
              <a
                href="javascript:;"
                className="text-gray-800 fw-bolder text-hover-primary mt-4 mb-2 fs-6"
              >
                {General.toTitleCase(monthlyFavourite)}
              </a>
            </div>
          </div>
        );
      });
    });
  }

  _renderContent(favouriteMonthly) {
    return favouriteMonthly?.[0]?.length != 0
      ? favouriteMonthly
      : this._renderNoContent();
  }

  _renderContent_(favouriteMonthly) {
    return favouriteMonthly;
  }

  render() {
    let { popularMonthlyFilters, popularMonthlyTools } = this.state;

    let filteredPopularMonthlyFilters = this._getFilteredMonthlyFavourite(
      popularMonthlyFilters?.monthly_popular_filters,
      this.state.startsAtFilters
    );

    let filteredPopularMonthlyTools = this._getFilteredMonthlyFavourite(
      popularMonthlyTools?.monthly_popular_tools,
      this.state.startsAtTools
    );

    const favouriteMonthlyFilters = this._renderMonthlyFavouriteContent(
      filteredPopularMonthlyFilters
    );

    const favouriteMonthlyTools = this._renderMonthlyFavouriteContent(
      filteredPopularMonthlyTools
    );

    return (
      <div className="card-body">
        <div id="latest-orders">
          <div className="d-flex">
            {this._renderContent(
              favouriteMonthlyFilters || favouriteMonthlyTools
            )}
          </div>
        </div>
      </div>
    );
  }
}
