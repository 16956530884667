
import { fabric } from 'fabric';

fabric.Image.filters.HSL = fabric.util.createClass(fabric.Image.filters.BaseFilter, {

    type: 'HSL',

    initialize: function(options) {
        options || (options = {});
        this.hsl = options.hsl || [0, 1, 1];
    },

    /**
     * Fragment source for the Highlight program
     * https://gamedev.stackexchange.com/questions/59797/glsl-shader-change-hue-saturation-brightness
     */
    fragmentSource: `precision highp float;
      uniform sampler2D uTexture;
      varying highp vec2 vTexCoord;

      uniform vec3 vHSV;

      vec3 rgb2hsv(vec3 c){
        vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
        vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
        vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

        float d = q.x - min(q.w, q.y);
        float e = 1.0e-10;
        return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
      }

      vec3 hsv2rgb(vec3 c){
        vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
        vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
        return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
      }

      void main(){
        vec4 textureColor = texture2D(uTexture, vTexCoord);
        vec3 fragRGB = textureColor.rgb;
        vec3 fragHSV = rgb2hsv(fragRGB).xyz;
        fragHSV.x += vHSV.x / 360.0;
        fragHSV.yz *= vHSV.yz;
        fragHSV.x = mod(fragHSV.x, 1.0);
        fragHSV.y = clamp(fragHSV.y, -2.0, 2.0);
        fragHSV.z = clamp(fragHSV.z, -0.0, 100.0);

        fragRGB = hsv2rgb(fragHSV);
        gl_FragColor = vec4(fragRGB, textureColor.w);
      }`,

    /**
      * Return WebGL uniform locations for this filter's shader.
      *
      * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
      * @param {WebGLShaderProgram} program This filter's compiled shader program.
      */
     getUniformLocations: function(gl, program) {
       return {
         vHSV: gl.getUniformLocation(program, 'vHSV'),
       };
     },

     /**
      * Send data from this filter to its shader program's uniforms.
      *
      * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
      * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
      */
     sendUniformData: function(gl, uniformLocations) {
       gl.uniform3fv(uniformLocations.vHSV, this.hsl);
     },

     /**
     * Returns object representation of an instance
     * @return {Object} Object representation of an instance
     */
    toObject: function() {
      return fabric.util.object.extend(this.callSuper('toObject'), {
        hsl: this.hsl,
      });
    }

});

fabric.Image.filters.HSL.fromObject = fabric.Image.filters.BaseFilter.fromObject;

const HSL = fabric.Image.filters.HSL

export { HSL }
