import React, { Component } from "react";

import moment from "moment";

import { Line } from "react-chartjs-2";

import ZoomPlugin from "chartjs-plugin-zoom";

import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Currency from "../utils/Currency";

import LatestUsersTable from "./components/tables/latest-users/LatestUsersTable";

import StatsCard from "./components/stats-cards/StatsCard";

export default class Revenue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statInfo: null,
      graphStats: null,
    };
  }

  componentDidMount() {
    this._getStats();
    this._getGraphStats();
  }

  _getGraphStats() {
    Backend.getRevenueStats()
      .then((graphStats) => {
        this.setState({ graphStats, isLoading: false });
        let graphMap = graphStats?.stats?.daily_revenue;

        const date = graphMap?.map((statInfo) => statInfo?.date);

        const totalRevenue = graphMap?.map(
          (statInfo) => statInfo?.total_revenue / 100
        );

        let graphData = {
          labels: date,

          datasets: [
            {
              label: "Revenue in Euros",
              data: totalRevenue,
              fill: true,
              borderColor: "#FFB800",
              borderWidth: 4,
              pointDot: true,
              backgroundColor: "#FFEAB3",
            },
          ],
        };

        this.setState({
          graphs: graphData,
          isLoading: false,
        });

        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 300);
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _handleResetZoom() {
    const chart = this.chartRef.chartInstance;
    chart.resetZoom();
  }

  _renderGraphs() {
    let { graphs, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    const options = {
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            sensitivity: 0.5,
            drag: false,
            enabled: true,
            mode: "x",
          },
        },
      },
    };

    let filteredData = graphs?.datasets[0]?.data.map((value) =>
      Math.max(0, value)
    );
    if (filteredData) {
      graphs.datasets[0].data = filteredData;
    }

    return (
      <>
        <div className="reset-btn ml-5">
          <button
            className="btn btn-secondary"
            onClick={() => this._handleResetZoom()}
          >
            Reset Zoom
          </button>
        </div>
        <Line
          ref={(reference) => (this.chartRef = reference)}
          data={graphs}
          options={options}
          plugins={[ZoomPlugin]}
        />
      </>
    );
  }

  _getStats() {
    this.setState({ isLoading: true });

    Backend.getStats()
      .then((stats) => {
        this.setState({ statInfo: stats, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    let { statInfo } = this.state;

    if (!statInfo) {
      return null;
    }

    const currentYear = moment().year();
    const currentMonth = moment().month() + 1; 

    const currentMonthStat =  statInfo.stats?.monthly.find((stat) => {
      const [year, month] = stat.date.split("-").map(Number);
      return year === currentYear && month === currentMonth;
    });

    const yearlyStats = statInfo.stats?.year;
    const allTimeStats = statInfo.stats?.all_time;

    return (
      <>
        <div className="container">
          <h1 className=" fw-bold ff-bitter mt-2 mb-10 fs-xl-1"> Revenue </h1>
          <div className="row wd-100">
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(currentMonthStat?.revenue_lite)}`,
                    title: "Revenue from Lite this month",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,4.25932872 C12.1488635,4.25921584 12.3000368,4.29247316 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 L12,4.25932872 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M12,4.25932872 L12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.277344,4.464261 11.6315987,4.25960807 12,4.25932872 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(yearlyStats?.revenue_lite)}`,
                    title: "Revenue from Lite this year",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,4.25932872 C12.1488635,4.25921584 12.3000368,4.29247316 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 L12,4.25932872 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M12,4.25932872 L12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.277344,4.464261 11.6315987,4.25960807 12,4.25932872 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(allTimeStats?.revenue_lite)}`,
                    title: "Total Revenue from Lite",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z"
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "
                        />
                        <path
                          d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="row wd-100">
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(currentMonthStat?.revenue_pro)}`,
                    title: "Revenue from Pro this month",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.3476862,4.32173209 11.9473121,4.11839309 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(yearlyStats?.revenue_pro)}`,
                    title: "Revenue from Pro this year",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.3476862,4.32173209 11.9473121,4.11839309 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
            <div className="col-lg-4 mb-4">
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(allTimeStats?.revenue_pro)}`,
                    title: "Total Revenue from Pro",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z"
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "
                        />
                        <path
                          d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="row wd-100">
            <div className="col-md-12">
              <div className="card card-custom gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Revenue Graph</h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  {this._renderGraphs()}
                  <div id="revenue" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-5">
            <LatestUsersTable
              title="Latest Users Signed Up"
              text="Subscribed On"
            />
          </div>
        </div>
      </>
    );
  }
}
