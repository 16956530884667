import React, { Component } from "react";

import AuthManager from "../../utils/AuthManager";
import Backend from "../../utils/Backend";
import Notify from "../../utils/Notify";
import General from "../../utils/General";
import Event from "../../utils/Event";

export default class AccountSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admin: General.clone(AuthManager.currentUser),
    };
  }

  _handleChange(e) {
    let { admin } = this.state;

    const key = e.target.name;
    const value = e.target.value;
    admin.user[key] = value;

    this.setState({ admin });
  }

  _isPasswordFormValid() {
    const { admin } = this.state;

    let { current_password, password, confirm_password } = admin.user;

    let isValid = true;
    let error = null;

    if (
      !current_password ||
      current_password === "" ||
      current_password.length < 6
    ) {
      isValid = false;
      error =
        "Please enter your current password. Password must be at least 6 characters, it must contain upper and lower case, and at least one special character.";
    } else if (!password || password === "" || password.length < 6) {
      isValid = false;
      error =
        "Please enter a valid new password. Password must be at least 6 characters, it must contain upper and lower case, and at least one special character.";
    } else if (password !== confirm_password) {
      isValid = false;
      error = "Passwords do not match";
    }

    if (error) {
      Notify.error(error);
    }

    return isValid;
  }

  _updateAccount() {
    let { admin } = this.state;

    let message = "Your password details have been updated successfully.";

    if (!this._isPasswordFormValid()) {
      return;
    }

    admin.user = {
      id: admin.user.id,
      password: admin.user.password,
      current_password: admin.user.current_password,
    };

    this.setState({ isLoading: true });

    Backend.updateAccount(admin)
      .then((data) => {
        this.setState({
          data,
          isLoading: false,
        });
        AuthManager.currentUser = data;
        Event.emit("USER_UPDATED");
        Notify.success(message);
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { admin, isLoading } = this.state;

    return (
      <div
        className="content d-flex flex-column flex-column-fluid pt-0"
        id="kt_content"
      >
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <div
              className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
              id="kt_create_account_stepper"
            >
              <div className="d-flex flex-row-fluid flex-center bg-body rounded">
                <form
                  className="py-20 w-100 w-xl-700px px-9"
                  noValidate="novalidate"
                  id="kt_create_account_form"
                >
                  <div className="current" data-kt-stepper-element="content">
                    <div className="w-100">
                      <div className="pb-10 pb-lg-15">
                        <h2 className="fw-bolder d-flex align-items-center text-dark">
                          Account Settings
                        </h2>
                      </div>

                      <div className="fv-row">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="fv-row mb-7">
                              <label className="fs-6 fw-bold mb-2">Email</label>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                value={admin?.user?.email}
                                disabled={admin?.user?.email}
                              />
                            </div>

                            <div className="fv-row mb-7">
                              <label className="fs-6 fw-bold mb-2">
                                Current Password
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                name="current_password"
                                onChange={(e) => {
                                  this._handleChange(e);
                                }}
                              />
                            </div>

                            <div className="fv-row mb-7">
                              <label className="fs-6 fw-bold mb-2">
                                New Password
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                onChange={(e) => {
                                  this._handleChange(e);
                                }}
                              />
                            </div>

                            <div className="fv-row mb-7">
                              <label className="fs-6 fw-bold mb-2">
                                Confirm New Password
                              </label>

                              <input
                                className="form-control"
                                type="password"
                                name="confirm_password"
                                onChange={(e) => {
                                  this._handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-20">
                        <div className="col-md-12">
                          <a href="/" className="btn btn-light me-3">
                            Cancel
                          </a>

                          <button
                            type="button"
                            className="btn btn-primary"
                            id="kt_file_manager_settings_submit"
                            disabled={isLoading}
                            onClick={(e) => {
                              e.preventDefault();
                              this._updateAccount();
                            }}
                          >
                            <span className="indicator-label">
                              Save Changes
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
