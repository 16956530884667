import { Lightroom } from "./Lightroom"

import { fabric } from 'fabric';

fabric.Image.filters.BlackAndWhiteGrain = fabric.util.createClass(fabric.Image.filters.BlackAndWhite, {

  name: "Midnight Grain",

  type: 'BlackAndWhiteGrain',

  initialize: function(options) {
    this.callSuper('initialize', options);

    this.grainAmount = 40
    this.grainSize = 25
    this.grainRoughness = 50
  },

});

const BlackAndWhiteGrain = fabric.Image.filters.BlackAndWhiteGrain

export { BlackAndWhiteGrain }
