import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.BlackAndWhite = fabric.util.createClass(
  fabric.Image.filters.Lightroom,
  {
    name: "Midnight",

    type: "BlackAndWhite",

    initialize: function (options) {
      this.callSuper("initialize", options);

      this.whites = 35
      this.blacks = 46
      this.contrast = 3
      this.exposure = 1.4
      this.vibrance = -1
      this.saturation = -100
      this.highlights = 7
      this.shadows = 46

      this.redSaturationAdjustment = 17
      this.orangeSaturationAdjustment = -2

      this.aquaLuminanceAdjustment = -21
      this.purpleLuminanceAdjustment = -48
      this.redLuminanceAdjustment = 45
      this.orangeLuminanceAdjustment = 7
      this.yellowLuminanceAdjustment = 34
      this.greenLuminanceAdjustment = -17
      this.intensity = 1
    },
  }
);

fabric.Image.filters.BlackAndWhite.fromObject =
  fabric.Image.filters.Lightroom.fromObject;

const BlackAndWhite = fabric.Image.filters.BlackAndWhite;

export { BlackAndWhite };
