import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.Creamy = fabric.util.createClass(
  fabric.Image.filters.Lightroom,
  {
    name: "Creamy",

    type: "Creamy",

    initialize: function (options) {
      this.callSuper("initialize", options);

      this.blacks = -4;
      this.contrast = 15;
      this.exposure = 0.55;
      this.highlights = -4;
      this.temperature = 9;
      this.blueSaturationAdjustment = -19;
      this.blueLuminanceAdjustment = -39;
      this.purpleLuminanceAdjustment = -4;
      this.magentaLuminanceAdjustment = -44;
      this.redSaturationAdjustment = -25;
      this.redLuminanceAdjustment = -3;
      this.orangeSaturationAdjustment = -28;
      this.orangeLuminanceAdjustment = -6;
      this.yellowSaturationAdjustment = -62;
      this.yellowLuminanceAdjustment = 35;
      this.greenSaturationAdjustment = -10;
      this.greenLuminanceAdjustment = 27;
      this.intensity = 0.95;
    },
  }
);

fabric.Image.filters.Creamy.fromObject =
  fabric.Image.filters.Lightroom.fromObject;

const Creamy = fabric.Image.filters.Creamy;

export { Creamy };
