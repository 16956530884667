import React from "react";

import AuthManager from "../utils/AuthManager";
import Email from "../utils/Email";
import Notify from "../utils/Notify";

const MODE_SIGNIN = "signin";
const MODE_FORGOT_PASSWORD = "forgot_password";
const MODE_RESET_PASSWORD = "reset_password";

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      code: [],
      password: "",
      confirm_password: "",
      isLoading: false,
      signUpStep: 1,
      mode: MODE_SIGNIN,
    };
  }

  _handleChange(index, event) {
    const code = [...this.state.code];
    code[index] = parseInt(event.target.value);
    this.setState({ code });
  }

  _isLoginFormValid() {
    let { password } = this.state;

    if (!password) {
      this.setState({ error: "Please enter your password." });
      return false;
    }
  }

  _isFormValid() {
    let { code, email, password, confirm_password, mode } = this.state;

    if (!Email.isValid(email)) {
      this.setState({ error: "Please enter a valid email address" });
      return false;
    }

    if (mode != MODE_FORGOT_PASSWORD && !password) {
      this.setState({ error: "Please enter your password." });
      return false;
    }

    if (mode == MODE_RESET_PASSWORD) {
      if (code.length === 0) {
        this.setState({ error: "Please enter a valid code" });
        return false;
      }
      if (!password || password.length < 6) {
        this.setState({ error: "Password must be at least 6 characters" });
        return false;
      }

      if (!confirm_password) {
        this.setState({ error: "Password must be at least 6 characters" });
        return false;
      } else if (password != confirm_password) {
        this.setState({ error: "Passwords do not match" });
        return false;
      }
    }

    return true;
  }

  _handleLogInClicked(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password } = this.state;

    this.setState({ isLoading: true });
    AuthManager.login(email, password, false)
      .then(() => {
        window.location = "/";
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _handleRequestResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password, confirm_password } = this.state;

    this.setState({ isLoading: true });
    AuthManager.requestResetPassword(email)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_RESET_PASSWORD,
          password: "",
          confirm_password: "",
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _handleResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password, code } = this.state;

    code = code.toString().replaceAll(",", "");

    this.setState({ isLoading: true });
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_SIGNIN,
          email: "",
          password: "",
          confirm_password: "",
        });
        Notify.success("Password reset successfully!");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _renderError() {
    let { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    );
  }

  render() {
    let { code, email, password, confirm_password, isLoading, mode } =
      this.state;

    const partialEmail = email.replace(
      /(\w{0})[\w.-]+@([\w.]+\w)/,
      "$1******@$2"
    );

    return (
      <>
        <div className="d-flex flex-column flex-root bg-body">
          <div className="d-flex flex-column flex-lg-row flex-column-fluid">
            <div
              className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative"
              style={{ backgroundColor: "#343434" }}
            >
              <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px">
                <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-30">
                  <a href="javascript:;" className="py-9 mb-5">
                    <img
                      alt="Logo"
                      src="assets/media/logos/logo.png"
                      className="h-100px"
                    />
                  </a>

                  <h1
                    className="fw-bolder fs-2qx pb-5 pb-md-10"
                    style={{ color: "#FFFFFF" }}
                  >
                    Admin Dashboard
                  </h1>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row-fluid py-10">
              <div className="d-flex flex-center flex-column flex-column-fluid">
                <div
                  className="w-lg-500px p-10 p-lg-15 mx-auto"
                  style={{
                    display: mode === MODE_SIGNIN ? "block" : "none",
                  }}
                >
                  <form
                    className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                    noValidate="novalidate"
                    id="kt_sign_in_form"
                  >
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">Sign In to LA Edit</h1>
                    </div>

                    {this._renderError()}

                    <div className="fv-row mb-10 fv-plugins-icon-container">
                      <label className="form-label fs-6 fw-bolder text-dark">
                        Email
                      </label>
                      <input
                        className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                        type="text"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) =>
                          this.setState({
                            email: e.target.value.trim(),
                            error: null,
                          })
                        }
                      />
                    </div>

                    <div className="fv-row mb-10 fv-plugins-icon-container">
                      <div className="d-flex flex-stack mb-2">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">
                          Password
                        </label>

                        <a
                          href="javascript:;"
                          className="link-primary fs-6 fw-bolder"
                          onClick={() =>
                            this.setState({
                              email: "",
                              mode: MODE_FORGOT_PASSWORD,
                              error: null,
                            })
                          }
                        >
                          Forgot Password ?
                        </a>
                      </div>

                      <input
                        className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                        type="password"
                        placeholder="Password"
                        autoComplete="off"
                        name="password"
                        value={password}
                        onChange={(e) =>
                          this.setState({
                            password: e.target.value,
                            error: null,
                          })
                        }
                      />
                    </div>

                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 w-100"
                        onClick={(e) => {
                          this._handleLogInClicked(e);
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading... " : "Log In"}
                      </button>
                    </div>
                    <div />
                  </form>
                </div>

                <div
                  className="w-lg-500px p-10 p-lg-15 mx-auto"
                  style={{
                    display: mode === MODE_FORGOT_PASSWORD ? "block" : "none",
                  }}
                >
                  <form
                    className="form w-100"
                    noValidate="novalidate"
                    id="kt_login_forgot_form"
                  >
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">Forgot Password ?</h1>

                      <div className="text-gray-400 fw-bold fs-4">
                        Enter your email to reset your password.
                      </div>
                    </div>

                    {this._renderError()}

                    <div className="fv-row mb-10 fv-plugins-icon-container">
                      <label className="form-label fs-6 fw-bolder text-dark">
                        Email
                      </label>
                      <input
                        className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                        type="text"
                        name="email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) =>
                          this.setState({
                            email: e.target.value.trim(),
                            error: null,
                          })
                        }
                      />
                    </div>

                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                      <button
                        type="button"
                        id="kt_login_forgot_submit"
                        className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                        onClick={(e) => this._handleRequestResetPassword(e)}
                        disabled={isLoading}
                        style={{ marginRight: "14px" }}
                      >
                        {isLoading ? "Loading... " : "Submit"}
                      </button>
                      <button
                        type="button"
                        id="kt_login_forgot_cancel"
                        className="btn btn-outline-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            email: "",
                            password: "",
                            mode: MODE_SIGNIN,
                            error: null,
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  className="w-lg-500px p-10 p-lg-15 mx-auto"
                  style={{
                    display: mode === MODE_RESET_PASSWORD ? "block" : "none",
                  }}
                >
                  <form
                    className="form w-100 mb-10"
                    noValidate="novalidate"
                    id="kt_sing_in_two_steps_form"
                  >
                    <div className="text-center mb-10" bis_skin_checked={1}>
                      <img
                        alt="Logo"
                        className="mh-125px"
                        src="assets/media/svg/misc/smartphone.svg"
                      />
                    </div>

                    <div className="text-center mb-10" bis_skin_checked={1}>
                      <h1 className="text-dark mb-3">Two Step Verification</h1>

                      <div
                        className="text-muted fw-bold fs-5 mb-5"
                        bis_skin_checked={1}
                      >
                        Enter the verification code we sent to
                      </div>

                      <div
                        className="fw-bolder text-dark fs-3"
                        bis_skin_checked={1}
                      >
                        {partialEmail}
                      </div>
                    </div>

                    <div className="mb-10 px-md-10" bis_skin_checked={1}>
                      <div
                        className="fw-bolder text-start text-dark fs-6 mb-1 ms-1"
                        bis_skin_checked={1}
                      >
                        Type your 4 digit security code
                      </div>

                      {this._renderError()}

                      <div
                        className="d-flex flex-wrap flex-stack"
                        bis_skin_checked={1}
                      >
                        <input
                          type="number"
                          data-inputmask="'mask': '9', 'placeholder': ''"
                          maxLength={1}
                          className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                          value={code[0]}
                          onChange={(e) => this._handleChange(0, e)}
                          inputMode="text"
                        />
                        <input
                          type="number"
                          data-inputmask="'mask': '9', 'placeholder': ''"
                          maxLength={1}
                          className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                          value={code[1]}
                          onChange={(e) => this._handleChange(1, e)}
                          inputMode="text"
                        />
                        <input
                          type="number"
                          data-inputmask="'mask': '9', 'placeholder': ''"
                          maxLength={1}
                          className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                          value={code[2]}
                          onChange={(e) => this._handleChange(2, e)}
                          inputMode="text"
                        />
                        <input
                          type="number"
                          name="input4"
                          data-inputmask="'mask': '9', 'placeholder': ''"
                          maxLength={1}
                          className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2"
                          value={code[3]}
                          onChange={(e) => this._handleChange(3, e)}
                          inputMode="text"
                        />
                      </div>
                    </div>

                    <div
                      className="mb-10 fv-row"
                      data-kt-password-meter="true"
                      bis_skin_checked={1}
                    >
                      <div className="mb-1" bis_skin_checked={1}>
                        <label className="form-label fw-bolder text-dark fs-6">
                          New Password
                        </label>

                        <div
                          className="position-relative mb-3"
                          bis_skin_checked={1}
                        >
                          <input
                            className="form-control form-control-lg form-control-solid"
                            type="password"
                            placeholder="Password"
                            name="password"
                            autoComplete="off"
                            value={password}
                            onChange={(e) =>
                              this.setState({
                                password: e.target.value,
                                error: null,
                              })
                            }
                            style={{
                              backgroundImage: 'url("data:image/png',
                              backgroundRepeat: "no-repeat",
                              backgroundAttachment: "scroll",
                              backgroundSize: "16px 18px",
                              backgroundPosition: "98% 50%",
                            }}
                          />
                          <span
                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility"
                          >
                            <i className="bi bi-eye-slash fs-2" />
                            <i className="bi bi-eye fs-2 d-none" />
                          </span>
                        </div>

                        <div
                          className="d-flex align-items-center mb-3"
                          data-kt-password-meter-control="highlight"
                          bis_skin_checked={1}
                        >
                          <div
                            className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                            bis_skin_checked={1}
                          />
                          <div
                            className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                            bis_skin_checked={1}
                          />
                          <div
                            className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
                            bis_skin_checked={1}
                          />
                          <div
                            className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
                            bis_skin_checked={1}
                          />
                        </div>
                      </div>

                      <div className="text-muted" bis_skin_checked={1}>
                        Use 6 or more characters with a mix of letters, numbers
                        &amp; symbols.
                      </div>
                    </div>

                    <div
                      className="fv-row mb-10 fv-plugins-icon-container"
                      bis_skin_checked={1}
                    >
                      <label className="form-label fw-bolder text-dark fs-6">
                        Confirm Password
                      </label>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="password"
                        name="confirm_password"
                        autoComplete="off"
                        value={confirm_password}
                        onChange={(e) =>
                          this.setState({
                            confirm_password: e.target.value,
                            error: null,
                          })
                        }
                        style={{
                          backgroundImage: 'url("data:image/png',
                          backgroundRepeat: "no-repeat",
                          backgroundAttachment: "scroll",
                          backgroundSize: "16px 18px",
                          backgroundPosition: "98% 50%",
                        }}
                      />
                    </div>

                    <div className="d-flex flex-center" bis_skin_checked={1}>
                      <button
                        className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            email: "",
                            password: "",
                            mode: MODE_SIGNIN,
                            error: null,
                          });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        id="kt_sing_in_two_steps_submit"
                        className="btn btn-lg btn-primary fw-bolder"
                      >
                        <span
                          className="indicator-label"
                          onClick={(e) => this._handleResetPassword(e)}
                          disabled={isLoading}
                        >
                          {/* {isLoading ? (
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          ) : (
                            "Submit"
                          )} */}

                          {isLoading ? "Loading... " : "Submit"}
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
                <div className="d-flex flex-center fw-bold fs-6">
                  <a
                    href="https://laeditapp.com/terms-and-conditions"
                    className="text-muted text-hover-primary px-2"
                    target="_blank"
                  >
                    Terms
                  </a>
                  <a
                    href="https://laeditapp.com/#contact_42_1600339621878"
                    className="text-muted text-hover-primary px-2"
                    target="_blank"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
