import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.Waves = fabric.util.createClass(
  fabric.Image.filters.Lightroom,
  {
    name: "Waves",

    type: "Waves",

    initialize: function (options) {
      this.callSuper("initialize", options);

      this.blacks = 15
      this.contrast = 11
      this.exposure = 2.05
      this.vibrance = 7
      this.saturation = 24
      this.highlights = -7
      this.shadows = 29
      this.temperature = -6
      this.tint = 18

      this.grainAmount = 3

      this.aquaHueAdjustment = -10
      this.aquaSaturationAdjustment = -3
      this.aquaLuminanceAdjustment = 46
      this.blueHueAdjustment = -6
      this.blueSaturationAdjustment = 32
      this.blueLuminanceAdjustment = 45
      this.purpleLuminanceAdjustment = 48
      this.magentaHueAdjustment = -6
      this.redHueAdjustment = 19
      this.redSaturationAdjustment = 21
      this.redLuminanceAdjustment = 15
      this.orangeHueAdjustment = -2
      this.orangeSaturationAdjustment = 21
      this.orangeLuminanceAdjustment = -14
      this.yellowHueAdjustment = -4
      this.yellowSaturationAdjustment = 11
      this.yellowLuminanceAdjustment = 4
      this.greenSaturationAdjustment = -31
      this.greenLuminanceAdjustment = 32
      this.intensity = 1
    },
  }
);

fabric.Image.filters.Waves.fromObject =
  fabric.Image.filters.Lightroom.fromObject;

const Waves = fabric.Image.filters.Waves;

export { Waves };
