import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.Memory = fabric.util.createClass(
  fabric.Image.filters.Lightroom,
  {
    name: "Memory",

    type: "Memory",

    initialize: function (options) {
      this.callSuper("initialize", options);

      this.whites = 11;
      this.blacks = 24;
      this.contrast = 17;
      this.exposure = 1.75;
      this.vibrance = 7;
      this.saturation = 4;
      this.highlights = 3;
      this.shadows = 62;
      this.temperature = -7;
      this.tint = -14;
      this.aquaHueAdjustment = 25;
      this.aquaSaturationAdjustment = -23;
      this.blueHueAdjustment = -33;
      this.blueSaturationAdjustment = 2;
      this.blueLuminanceAdjustment = 8;
      this.purpleSaturationAdjustment = -10;
      this.magentaSaturationAdjustment = -10;
      this.magentaLuminanceAdjustment = 11;
      this.redHueAdjustment = 3;
      this.redSaturationAdjustment = 1;
      this.redLuminanceAdjustment = 8;
      this.orangeHueAdjustment = 8;
      this.orangeSaturationAdjustment = 3;
      this.orangeLuminanceAdjustment = 17;
      this.yellowHueAdjustment = -38;
      this.yellowSaturationAdjustment = -24;
      this.yellowLuminanceAdjustment = 11;
      this.greenHueAdjustment = -34;
      this.greenSaturationAdjustment = -54;
      this.greenLuminanceAdjustment = -7;
      this.intensity = 1;
    },
  }
);

fabric.Image.filters.Memory.fromObject =
  fabric.Image.filters.Lightroom.fromObject;

const Memory = fabric.Image.filters.Memory;

export { Memory };
