import React, { Component } from "react";

import moment from "moment";

import General from "../../utils/General";

export default class MonthlyCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      months: [],
      value: moment().format("MMM"),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  componentDidMount() {
    const currentMonth = moment().format("MMM");
    const previousMonths = Array.from({ length: 9 }, (value, index) =>
      moment()
        .subtract(index + 1, "months")
        .format("MMM")
    ).filter((month) => month !== currentMonth);

    this.setState({
      months: [currentMonth, ...previousMonths],
    });
  }

  render() {
    const { months, value } = this.state;
    const { startsAtFilters, startsAtTools } = this.props;

    return (
      <div className="card-toolbar">
        <ul className="nav nav-tabs nav-tabs-line">
          {months.map((month, index) => {
            return (
              <li className="nav-item" key={General.uuid()}>
                <a
                  href="javascript:;"
                  className={value === month ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  onClick={() => {
                    this.props.onDateChange(
                      month,
                      startsAtFilters,
                      startsAtTools
                    );
                    this.setState({
                      value: month,
                    });
                  }}
                >
                  {month}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
