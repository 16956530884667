import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.MochaV6 = fabric.util.createClass(
  fabric.Image.filters.Mocha,
  {
    name: "Cinnamon",

    type: "MochaV6",

    initialize: function (options) {
      this.callSuper("initialize", options);

      this.whites = -17;
      this.blacks = -27;
      this.contrast = -2;
      this.exposure = -2.2;
      this.vibrance = -20;
      this.saturation = -7;
      this.highlights = -28;
      this.shadows = -13;
      this.temperature = 28;
      this.tint = 25;
      this.grainAmount = 10;
      this.grainSize = 15;
      this.grainRoughness = 25;
      this.aquaSaturationAdjustment = -41;
      this.aquaLuminanceAdjustment = -19;
      this.blueHueAdjustment = -14;
      this.blueSaturationAdjustment = 8;
      this.redSaturationAdjustment = 3;
      this.redLuminanceAdjustment = -10;
      this.orangeHueAdjustment = -10;
      this.orangeSaturationAdjustment = -10;
      this.orangeLuminanceAdjustment = -51;
      this.yellowSaturationAdjustment = -2;
      this.yellowLuminanceAdjustment = -9;
      this.greenSaturationAdjustment = -10;
      this.intensity = 1;
    },
  }
);

fabric.Image.filters.MochaV6.fromObject = fabric.Image.filters.Mocha.fromObject;

const MochaV6 = fabric.Image.filters.MochaV6;

export { MochaV6 };
