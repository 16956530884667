import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.Lisbon = fabric.util.createClass(
  fabric.Image.filters.Lightroom,
  {
    name: "Lisbon",

    type: "Lisbon",

    initialize: function (options) {
      this.callSuper("initialize", options);

      this.whites = 1
      this.blacks = 25
      this.contrast = -3
      this.exposure = -1.3
      this.vibrance = -21
      this.saturation = -11
      this.highlights = -42
      this.shadows = -57
      this.temperature = 2
      this.tint = -38

      this.aquaHueAdjustment = 15
      this.aquaSaturationAdjustment = -23
      this.aquaLuminanceAdjustment = -10
      this.blueHueAdjustment = -33
      this.blueSaturationAdjustment = 2
      this.blueLuminanceAdjustment = 10
      this.purpleSaturationAdjustment = -10
      this.magentaSaturationAdjustment = -99
      this.redHueAdjustment = 21
      this.redLuminanceAdjustment = 28
      this.orangeHueAdjustment = -13
      this.orangeSaturationAdjustment = 23
      this.orangeLuminanceAdjustment = -69
      this.yellowHueAdjustment = -75
      this.yellowSaturationAdjustment = -50
      this.yellowLuminanceAdjustment = -68
      this.greenHueAdjustment = -61
      this.greenSaturationAdjustment = -55
      this.greenLuminanceAdjustment = -33
      this.intensity = 1
    },
  }
);

fabric.Image.filters.Lisbon.fromObject =
  fabric.Image.filters.Lightroom.fromObject;

const Lisbon = fabric.Image.filters.Lisbon;

export { Lisbon };
