import runtimeEnv from "@mars/heroku-js-runtime-env";

    const env = runtimeEnv();

    window.Api = {};
    
    window.Api.Base                        = env.REACT_APP_API_BASE;
    
    window.Api.User = {};
    
    window.Api.User.Base                    = window.Api.Base + "/user";
    
    window.Api.User.Login                   = window.Api.User.Base + "/login";
    window.Api.User.Logout                  = window.Api.User.Base + "/logout";
    window.Api.User.Info                    = window.Api.User.Base + "/info";
    window.Api.User.RequestResetPassword    = window.Api.User.Base + "/request-reset-password";
    window.Api.User.ResetPassword           = window.Api.User.Base + "/reset-password";
    window.Api.User.RefreshToken            = window.Api.User.Base + "/refresh-token";

    window.Api.Customers                    = window.Api.Base + "/customers";

    window.Api.Admins                       = window.Api.Base + "/admins";

    window.Api.Stats                        = window.Api.Base + "/stats"

    window.Api.Images                       = window.Api.Base + "/images";
    
    window.Api.StripePublishableKey         = env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    
    window.Api.CloudFrontBaseUrl            = env.REACT_APP_CLOUDFRONT_BASE_URL




   


