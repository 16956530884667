import { Lightroom } from "./Lightroom";

import { fabric } from "fabric";

fabric.Image.filters.BlackAndWhiteGrainV2 = fabric.util.createClass(
  fabric.Image.filters.BlackAndWhiteV2,
  {
    name: "Moonlight Grain",

    type: "BlackAndWhiteGrainV2",

    initialize: function (options) {
      this.callSuper("initialize", options);
      this.whites = 7;
      this.blacks = 11;
      this.contrast = 12;
      this.exposure = -0.8;
      this.vibrance = -1;
      this.saturation = -100;
      this.highlights = 14;
      this.shadows = 38;
      this.grainAmount = 40;
      this.grainSize = 25;
      this.grainRoughness = 50;
      this.purpleLuminanceAdjustment = 4;
      this.magentaLuminanceAdjustment = 5;
      this.redLuminanceAdjustment = -4;
      this.orangeSaturationAdjustment = -4;
      this.orangeLuminanceAdjustment = -2;
      this.yellowLuminanceAdjustment = -14;
      this.greenLuminanceAdjustment = -4;
      this.intensity = 1;
    },
  }
);

const BlackAndWhiteGrainV2 = fabric.Image.filters.BlackAndWhiteGrainV2;

export { BlackAndWhiteGrainV2 };
