import React from "react";

import logo from "./logo.svg";
import NotFound from "./pages/shared/NotFound";
import AuthApp from "./pages/App";
import Login from "./pages/Login";

import AuthManager from "./utils/AuthManager";

import Api from "./constants/Api";
import General from "./constants/General";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Pagination from "./pages/components/tables/Pagination";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ReactTableDefaults } from "react-table";

import { toast } from "react-toastify";

import "./assets/plugins/global/plugins.bundle.css";

import "./assets/css/style.bundle.css";

import "./assets/css/custom.css";

import "./App.css";
import './assets/scss/app.scss'


Object.assign(ReactTableDefaults, {
  minRows: 2,
  PaginationComponent: Pagination,
});

toast.configure();
export default class App extends React.Component {
  componentDidMount() {
    let scripts = [];

    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL + "/assets/plugins/global/plugins.bundle.js"
      )
    )

    scripts.push(
      this._getScript(process.env.PUBLIC_URL + "/assets/js/scripts.bundle.js")
    );

    scripts.push(
      this._getScript(
        process.env.PUBLIC_URL +
          "/assets/js/custom/authentication/sign-in/general.js"
      )
    );

    setTimeout(() => {
      this._addScripts(scripts);
    }, 3000);
  }

  _getScript(path) {
    const script = document.createElement("script");

    script.src = path;

    return script;
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i];
      document.body.appendChild(script);
      await this._sleep(500);
    }
  }

  _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
 
  render() {
    return (
      <>
       <BrowserRouter>
        <Switch>
          <Route path="/404" component={NotFound} />
           <Route exact path="/account" component={Login}/> 
           <AuthenticatedRoute path="/" component={AuthApp} />
          <Redirect to="/404" />
        </Switch>
       </BrowserRouter>
      </>
    );
  }
}

























