import React from "react";

import Card from "../Card";

import ReactTable from "react-table";

import FetchHelper from "../../../utils/FetchHelper";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";

import "react-table/react-table.css";

const OBJECTS_FILTERS = {
  name: {
    api: "objects",
    display: "Status",
  },
  values: [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Hidden",
      value: "deleted",
    },
  ],
};

export default class BaseTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      pagesNo: 0,
      searchTerm: "",
      csvData: [],
      filterValue: { objects: "active" },
      searchFilter: this.props.searchFilter,
      filters: this.props.filters ? [...this.props.filters] : [],
    };

    this.reactTable = React.createRef();
    this.csvLink = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    let refresh = this.props.endpoint !== nextProps.endpoint;
    this.setState(nextProps, () => {
      if (refresh) {
        this.refresh();
      }
    });
  }

  refresh() {
    let current = this.reactTable.current;
    if (current) {
      this.setState(
        { pagesNo: 1, loading: false },
        this._fetchData(current.state, current)
      );
    }
  }

  _handleSearch = General.debounce(() => {
    let current = this.reactTable.current;
    this.setState({ updated: !this.state.updated });
  }, 500);

  _handleFilterChange(event) {
    let { filterValue, updated } = this.state;

    filterValue[event.target.name] = event.target.value;
    this.setState({ filterValue }, () => this._handleSearch());
  }

  _getUrl(endpoint, state) {
    let params = {
      ...this.props.params,
      page_size: state.pageSize,
      page: state.page + 1,
      pagination_type: "page",
      search_term: this.props.searchTerm || this.state.searchTerm,
    };

    let sorted = state.sorted[0];
    if (sorted) {
      let orderBy = sorted.id;
      orderBy = orderBy.replace(/\./g, "__");
      if (sorted.desc) {
        orderBy = `-${orderBy}`;
      }
      params["order_by"] = orderBy;
    }

    if (this.state.filterValue) {
      Object.entries(this.state.filterValue).map((filter) => {
        params[filter[0]] = filter[1];
      });
    }

    return this._addParams(endpoint, params);
  }

  _fetchData(state, instance) {
    this.setState({ loading: true });
    let url = this._getUrl(this.props.endpoint, state);

    if (this.props.endpoint === "") {
      this.setState({
        data: this.props.mockData,
        pagesNo: 1,
        loading: false,
      });
      return;
    }
    FetchHelper.get(url, false)
      .then((response) => {
        this.setState({
          data: response.results,
          pagesNo: Math.ceil(response.count / state.pageSize),
          totalRecords: response.count,
          loading: false,
        });
      })
      .catch((error) => {});
  }

  _addParams(url, params) {
    if (Object.keys(params).length == 0) {
      return url;
    }

    // TODO: switch to an actual url helper here to avoid bugs/edge cases
    if (url.indexOf("?") == -1) {
      url += "?";
    } else if (!url.endsWith("&")) {
      url += "&";
    }

    Object.keys(params).forEach(function (key) {
      url += key + "=" + params[key] + "&";
    });

    // remove last '&'
    url = url.slice(0, -1);
    return url;
  }

  _exportPressed() {
    if (this.state.exporting) {
      return;
    }

    let exportableColumns = this.props.columns.filter(
      (column) => column.exportable != false
    );

    let prefetchRelated = [];
    let columns = exportableColumns.map((column) => {
      let data = {
        name: column.Header,
        accessor: column.id || column.accessor,
        type: column.type,
        format: column.format,
      };

      data.accessor.replace(/\./g, "__");
      if (data.accessor.indexOf("__") !== -1) {
        prefetchRelated.push(data.accessor);
      }
      return data;
    });

    let data = {
      export: true,
      options: encodeURIComponent(
        JSON.stringify({
          columns,
          prefetch_related: prefetchRelated,
          select_related: [],
        })
      ),
      search_term: this.state.searchTerm,
    };

    if (this.state.searchFilter) {
      data[this.props.searchFilterName] = this.state.searchFilter;
    }

    if (this.props.defaultSorted && this.props.defaultSorted.length > 0) {
      data.order_by = this.props.defaultSorted[0].id.replace(/\./g, "__");
      if (this.props.defaultSorted[0].desc) {
        data.order_by = "-" + data.order_by;
      }
    }

    let endpoint = this._addParams(this.props.endpoint, data);

    this.setState({ exporting: true });
    FetchHelper.download(endpoint, `${this.props.title}.csv`)
      .then(() => {
        this.setState({ exporting: false });
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _renderHeaderLeftContent() {
    let { filters } = this.state;

    return (
      <>
        <div className="d-flex align-items-center position-relative">
          <span className="svg-icon svg-icon-1 position-absolute ms-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height={2}
                rx={1}
                transform="rotate(45 17.0365 15.1223)"
                fill="currentColor"
              />
              <path
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <input
            type="text"
            className="form-control form-control-solid w-250px ps-14"
            placeholder={this.props.searchPlaceholder}
            onChange={(e) => {
              this.setState(
                {
                  searchTerm: e.target.value,
                },
                () => this._handleSearch()
              );
            }}
          />
          <span>
            <i className="flaticon2-search-1 text-muted"></i>
          </span>
        </div>
        {filters &&
          filters.map((filter) => {
            return (
              <div className="col my-2 my-md-0 ms-5">
                <div className="d-flex align-items-center">
                  <label className="mr-3 mb-0 d-none d-md-block">
                    {General.toTitleCase(filter.name.display)}:
                  </label>
                  <select
                    className="form-control"
                    name={filter.name.api}
                    onChange={(e) => {
                      this._handleFilterChange(e);
                    }}
                    defaultValue={this.props.searchFilter}
                  >
                    {filter.values.map((filterOptions) => {
                      return (
                        <option value={filterOptions.value || ""}>
                          {filterOptions.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            );
          })}
      </>
    );
  }

  render() {
    const { data, pagesNo, loading, totalRecords } = this.state;

    return (
      <Card
        title={this.props.title}
        icon={this.props.icon}
        renderToolbar={this.props.renderToolbar()}
        className={this.props.className}
        loading={loading}
        showHeader={this.props.showHeader}
        showExport={this.props.showExport}
        onExportClicked={() => this._exportPressed()}
        showSearch={true}
        renderHeaderLeftContent={() => this._renderHeaderLeftContent()}
      >
        <div>
          {this.props.showFilter && this.props.showSearch && (
            <div class="row mb-7">
              <div class="col-md-12">
                <div class="dataTables_filter">
                  <div className="row">{this._renderHeaderLeftContent()}</div>
                </div>
              </div>
            </div>
          )}
          <div className="table-responsive">
            <ReactTable
              key={this.state.updated}
              ref={this.reactTable}
              manual
              data={data}
              pages={pagesNo}
              totalRecords={totalRecords}
              loading={loading}
              LoadingComponent={Loading}
              onFetchData={this._fetchData.bind(this)}
              columns={this.props.columns}
              getTdProps={this.props.getTdProps}
              getTrProps={this.props.getTrProps}
              getTheadProps={this.props.getTheadProps}
              getTheadThProps={this.props.getTheadThProps}
              showPagination={this.props.showPagination}
              showPaginationTop={this.props.showPaginationTop}
              showPaginationBottom={this.props.showPaginationBottom}
              defaultSorted={this.props.defaultSorted}
              SubComponent={this.props.SubComponent}
              NoDataComponent={() => {
                return (
                  <div className="rt-noData">{this.props.noDataMessage}</div>
                );
              }}
            />
          </div>
        </div>
      </Card>
    );
  }
}

BaseTable.defaultProps = {
  params: {},
  label: "Search:",
  searchPlaceholder: "Search Users",
  showHeader: true,
  showFilter: true,
  icon: null,
  filters: null,
  searchFilter: null,
  searchFilterName: "name",
  noDataMessage: "No documents found",
  showSearch: true,
  showPagination: true,
  showPaginationTop: false,
  showPaginationBottom: true,
  showExport: false,
  getTdProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingLeft: 10,
      },
    };
  },
  getTrProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    };
  },
  getTheadProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        boxShadow: "0 1px 1px 0 rgba(0,0,0,0.1)",
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: "left",
      },
    };
  },
  getTheadThProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        textAlign: "left",
        paddingLeft: 10,
      },
    };
  },
  SubComponent: null,
  renderHeaderRightContent: () => null,
  renderToolbar: () => null,
};

class Loading extends React.Component {
  render() {
    return this.props.loading ? (
      <div className="-loading -active">
        <div className="-loading-inner"></div>
      </div>
    ) : null;
  }
}
