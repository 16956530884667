
import { fabric } from 'fabric';

fabric.Image.filters.Exposure = fabric.util.createClass(fabric.Image.filters.BaseFilter, {

  type: 'Exposure',

  /**
   * Fragment source for the Exposure program
   * https://timseverien.com/posts/2020-06-19-colour-correction-with-webgl/
   */
  fragmentSource: `precision highp float;
    uniform sampler2D uTexture;
    varying vec2 vTexCoord;

    uniform float uExposure;

    vec3 adjustExposure(vec3 color, float value) {
		   return color * (1.0 + value);
		}

    void main() {
      vec4 texel = texture2D(uTexture, vTexCoord);
      vec3 color = texel.rgb;

      color = adjustExposure(color, uExposure);
      gl_FragColor = vec4(color, texel.a);
    }`,

  applyTo2d: function(options) {
    if (this.exposure === 0) {
      return;
    }

    var imageData = options.imageData, data = imageData.data, i, len = data.length;

    let exposureMultiplier = 1.0 + this.exposure
    for (i = 0; i < len; i += 4) {
      // TODO implement
      // make image green when WEBGL not supported
      data[i] *= exposureMultiplier
      data[i + 1] *= exposureMultiplier;
      data[i + 2] *= exposureMultiplier;
    }

  },

  /**
    * Return WebGL uniform locations for this filter's shader.
    *
    * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
    * @param {WebGLShaderProgram} program This filter's compiled shader program.
    */
   getUniformLocations: function(gl, program) {
     return {
       uExposure: gl.getUniformLocation(program, 'uExposure'),
     };
   },

   /**
    * Send data from this filter to its shader program's uniforms.
    *
    * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
    * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
    */
   sendUniformData: function(gl, uniformLocations) {
     gl.uniform1f(uniformLocations.uExposure, this.exposure);
   },
});

fabric.Image.filters.Exposure.fromObject = fabric.Image.filters.BaseFilter.fromObject;

const Exposure = fabric.Image.filters.Exposure

export { Exposure }
