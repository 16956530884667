import React, { Component } from "react";

import AuthManager from "../../utils/AuthManager";
import Event from "../../utils/Event";
import General from "../../utils/General";

import UserPanel from "./UserPanel";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthManager.currentUser,
    };
  }

  componentDidMount() {
    Event.on("USER_UPDATED", () =>
      this.setState({
        currentUser: AuthManager.currentUser,
      })
    );
  }

  componentWillUnmount() {
    Event.off("USER_UPDATED");
  }

  render() {
    const { currentUser } = this.state;

    const userAvatar = currentUser.user?.avatar?.original;
    const userFirstName = currentUser.user?.first_name;
    const userLastName = currentUser.user?.last_name;

    return (
      <>
        <div id="kt_header" className="header align-items-stretch">
          <div className="container-fluid d-flex align-items-stretch justify-content-between">
            <div
              className="d-flex align-items-center d-lg-none ms-n3 me-1"
              title="Show aside menu"
            >
              <div
                className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                id="kt_aside_mobile_toggle"
              >
                <span className="svg-icon svg-icon-2x mt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                      fill="black"
                    />
                    <path
                      opacity="0.3"
                      d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
              <a href="../../demo1/dist/index.html" className="d-lg-none">
                <img
                  alt="Logo"
                  src="assets/media/logos/logo-2.svg"
                  className="h-30px"
                />
              </a>
            </div>

            <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
              <div className="d-flex align-items-stretch" id="kt_header_nav">
                <div
                  className="header-menu align-items-stretch"
                  data-kt-drawer="true"
                  data-kt-drawer-name="header-menu"
                  data-kt-drawer-activate="{default: true, lg: false}"
                  data-kt-drawer-overlay="true"
                  data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                  data-kt-drawer-direction="end"
                  data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                  data-kt-swapper="true"
                  data-kt-swapper-mode="prepend"
                  data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                >
                  <div
                    className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                    id="#kt_header_menu"
                    data-kt-menu="true"
                  >
                    <div className="menu-item me-lg-1">
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        <span className="form-check-label fw-bold">
                          Admin Dashboard
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-stretch flex-shrink-0">
                <div className="d-flex align-items-stretch flex-shrink-0">
                  <div
                    className="d-flex align-items-center ms-1 ms-lg-3"
                    id="kt_header_user_menu_toggle"
                  >
                    <div
                      className="cursor-pointer symbol symbol-30px symbol-md-40px"
                      data-kt-menu-trigger="click"
                      data-kt-menu-attach="parent"
                      data-kt-menu-placement="bottom-end"
                      style={{
                        backgroundColor: !userAvatar && "whitesmoke",
                        width: !userAvatar && "40px",
                        height: !userAvatar && "40px",
                      }}
                    >
                      {userAvatar ? (
                        <img src={userAvatar} alt="User" />
                      ) : (
                          <h1 className="m-0 text-center my-2">
                            {General.getInitials(
                              `${userFirstName} ${userLastName}`
                            )}
                          </h1>
                      )}
                    </div>
                    <UserPanel currentUser={currentUser}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
